import React, { useReducer, useEffect, useState } from "react";
import {
  AppContext,
  appReducer,
  initialAppState,
  AppACTIONS,
} from "./components/common/context/AppContext";

import {
  DataContext,
  dataReducer,
  initialDataState,
} from "./components/common/context/DataContext";
import "./App.css";
import GlobalFont from "../src/components/common/GlobalFont";
import GlobalStyle from "../src/components/common/GlobalStyle";
import Header from "../src/components/common/Header";
import StepContent from "./components/common/StepContent";
import StepTracker from "./components/common/StepTracker";
import ErrorPage from "./components/ErrorPage";

import { getFmaUrl, getFmaKeys } from "./helpers/fdma-configs";
import { callMouseFlow } from "./helpers/util";

import LoadingSpinner from "./components/common/LoadingSpinner";

function App() {
  const [appState, appDispatch] = useReducer(appReducer, initialAppState);
  const [dataState, dataDispatch] = useReducer(dataReducer, initialDataState);

  const dataValue = {
    dataState,
    dataDispatch,
  };
  const value = {
    appState,
    appDispatch,
  };

  const [catToken, setCatToken] = useState(null);
  const [fmaLoading, setFmaLoading] = useState(true);

  // get orderId
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const urlParamOrderId = urlParams.get("orderId");
  let orderId = appState.orderId || urlParamOrderId;

  const runFmaScript = () => {
    if (!document.getElementById("fmaScript")) {
      const fmaScript = document.createElement("script");
      fmaScript.src = getFmaUrl;
      fmaScript.id = "fmaScript";
      fmaScript.setAttribute("data-fma-script", true);
      fmaScript.setAttribute("data-redirect-url", window.location.origin);
      fmaScript.setAttribute("data-client-id", getFmaKeys.clientId);
      fmaScript.setAttribute("data-app-id", getFmaKeys.appId);
      fmaScript.setAttribute("data-lang", "en_us");
      fmaScript.setAttribute("data-property-key", "owner_ford");
      fmaScript.async = true;

      fmaScript.addEventListener("load", () => {
        // check if user is FMA authenticated
        window.fma.model.config.enablePKCE = "true";
        if (orderId) {
          window.fma.model.config.state = orderId;
        }
        document.body.addEventListener("fma_unauthenticated", () => {
          window.fma.login();
        });

        document.body.addEventListener("fma_authenticated", () => {
          if (window.fma.state) {
            appDispatch({
              type: AppACTIONS.orderIdUpdated,
              payload: window.fma.state,
            });
            orderId = window.fma.state;
          }
          setFmaLoading(false);
          if (window.fma.CATBundle.access_token) {
            setCatToken(window.fma.CATBundle.access_token);
            appDispatch({
              type: AppACTIONS.accessTokenUpdated,
              payload: window.fma.CATBundle.access_token,
            });
          }
        });
      });
      document.body.appendChild(fmaScript);
    }
  };

  useEffect(() => {
    runFmaScript();
  }, []);

  function renderScheduler() {
    console.log(orderId, catToken, fmaLoading);

    if (fmaLoading) {
      return (
        <div>
          <LoadingSpinner showLoadingSpinner={true} />;
        </div>
      );
    } else if (!(orderId && catToken && !fmaLoading)) {
      return (
        <div>
          <Header />
          <ErrorPage />
        </div>
      );
    } else {
      return (
        <AppContext.Provider value={value}>
          <DataContext.Provider value={dataValue}>
            <Header />
            <LoadingSpinner showLoadingSpinner={dataState.showLoadingSpinner} />
            <main>
              {catToken && orderId && <StepTracker />}
              <StepContent />
            </main>
          </DataContext.Provider>
        </AppContext.Provider>
      );
    }
  }

  return (
    <React.Fragment>
      <GlobalFont />
      <GlobalStyle />
      <div className="dds-app-wrapper-container">{renderScheduler()}</div>
    </React.Fragment>
  );
}
export default App;
