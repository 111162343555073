import React, { useContext, useState } from "react";
import styled from "styled-components";
import { DataContext } from "./context/DataContext";
import dummyMache from "../../assets/img/dummyMache.png";
import dummyLightning from "../../assets/img/dummyLightning.png";

const StyledVehInfo = styled.div`
  && {
    display: flex;
    flex-direction: row;
    .dds-veh-img {
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      background: white;
      border: 1px solid #d9d9d9;
      width: 54px;
      height: 54px;
      img {
        width: 50px;
        height: 30px;
      }
    }
    .dds-year-model-wrapper {
      margin-left: 10px;
      span {
        font-size: 20px;
        font-weight: 700;
        color: var(--secondary);
      }
      div {
        font-weight: 400;
        font-size: 16px;
        color: var(--secondary);
        margin-top: 5px;
      }
    }
  }
`;

const VehInfo = (data) => {
  const { imageUrl, model, year } = data.data;
  const [imgSrc, setImgSrc] = useState(imageUrl);

  const onImgError = () =>
    setImgSrc(model.includes("Mustang") ? dummyMache : dummyLightning);

  return (
    <StyledVehInfo>
      <div className="dds-veh-img">
        <img src={imageUrl || imgSrc} onError={onImgError} />
      </div>

      <div className="dds-year-model-wrapper">
        <span>Your vehicle</span>
        <div>
          {year} {model}
        </div>
      </div>
    </StyledVehInfo>
  );
};
export default VehInfo;
