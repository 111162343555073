import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../Home";
import Appointments from "../Appointments";
import Summary from "../Summary";
import AppointmentRequest from "../AppointmentRequest";
import CancelAppointment from "../CancelAppointment";
import ErrorPage from "../ErrorPage";
import CompletedPage from "../CompletedPage";

import AppointmentConfirmationPage from "../AppointmentConfirmationPage";

const StepContent = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/appointments" element={<Appointments />}></Route>
        <Route path="/summary" element={<Summary />}></Route>
        <Route
          path="/appointmentRequest"
          element={<AppointmentRequest />}
        ></Route>
        <Route
          path="/appointmentConfirmation"
          element={<AppointmentConfirmationPage />}
        ></Route>
        <Route path="/cancel" element={<CancelAppointment />}></Route>
        <Route path="/error" element={<ErrorPage />}></Route>
        <Route path="/completed" element={<CompletedPage />}></Route>
      </Routes>
    </BrowserRouter>
  );
};
export default StepContent;
