function pageLoad(digitaldata, trackingString) {
  try {
    window.digitaldata.event = [];
    window.digitaldata = digitaldata;
    if (digitaldata.page.variantName) {
      window._satellite.track(digitaldata.page.variantName);
    } else {
      window._satellite.track(trackingString);
    }
  } catch (err) {
    console.log("Analytics blocked", err);
  }
}

export default {
  vehiclePickUpStart: (nameplate, category, model, year) => {
    pageLoad({
      page: {
        pageName: `fv:manage:dds:vehicle pickup:start:${nameplate}`,
        siteSection: "manage",
        hierarchy: `shopping tools:manage:${category}:${nameplate}`,
        userLanguage: "eng",
        pageNameNoVehicle: "fv:manage:dds:vehicle pickup:start",
        tool: "event:vehicle pickup start",
        client: "ford-us",
        site: "fordvehicles.com",
        radUIVersion: "ui:rad:pc",
        variantName: "manageCvdtStart",
      },
      vehicle: {
        modelYear: `${year}`,
        nameplate: `${nameplate}`,
        bodyModelTrim: `${model}`,
      },
      event: {
        action: "vehicle pickup start",
      },
      user: {
        loginStatus: "logged in",
        //errorTracking: "<errorActionName>",
      },
    });
  },
  vehiclePickupDateTime: (nameplate, category, model, year) => {
    pageLoad({
      page: {
        pageName: `fv:manage:dds:vehicle pickup:date and time:${nameplate}`,
        siteSection: "manage",
        hierarchy: `shopping tools:manage:${category}:${nameplate}`,
        userLanguage: "eng",
        pageNameNoVehicle: "fv:manage:dds:vehicle pickup:date and time",
        client: "ford-us",
        site: "fordvehicles.com",
        radUIVersion: "ui:rad:pc",
        variantName: "manageCvdtNext",
      },
      vehicle: {
        modelYear: `${model} ${year}`,
        nameplate: `${nameplate}`,
        bodyModelTrim: `${model}`,
      },
      user: {
        loginStatus: "logged in",
        //  errorTracking: "<errorActionName>",
      },
    });
  },
  vehiclePickupReviewDetails: (nameplate, category, model, year) => {
    pageLoad({
      page: {
        pageName: `fv:manage:dds:vehicle pickup:review details:${nameplate}`,
        siteSection: "manage",
        hierarchy: `shopping tools:manage:${category}:${nameplate}`,
        userLanguage: "eng",
        pageNameNoVehicle: "fv:manage:dds:vehicle pickup:review details",
        client: "ford-us",
        site: "fordvehicles.com",
        radUIVersion: "ui:rad:pc",
        variantName: "manageCvdtNext",
      },
      vehicle: {
        modelYear: `${model} ${year}`,
        nameplate: `${nameplate}`,
        bodyModelTrim: `${model}`,
      },
      user: {
        loginStatus: "logged in",
        //errorTracking: "<errorActionName>",
      },
    });
  },
  vehiclePickupRequestAppointment: (nameplate, category, model, year) => {
    pageLoad({
      page: {
        pageName: `fv:manage:dds:vehicle pickup:request appt confirmed:${nameplate}`,
        siteSection: "manage",
        hierarchy: `shopping tools:manage:${category}:${nameplate}`,
        userLanguage: "eng",
        pageNameNoVehicle:
          "fv:manage:dds:vehicle pickup:request appt confirmed",
        client: "ford-us",
        site: "fordvehicles.com",
        tool: "event:vehicle pickup complete",
        radUIVersion: "ui:rad:pc",
        variantName: "manageCvdtComplete",
      },
      vehicle: {
        modelYear: `${model} ${year}`,
        nameplate: `${nameplate}`,
        bodyModelTrim: `${model}`,
      },
      user: {
        loginStatus: "logged in",
        // errorTracking: "<errorActionName>",
      },
    });
  },
  vehiclePickupAppointmentConfirmed: (nameplate, category, model, year) => {
    pageLoad({
      page: {
        pageName: `fv:manage:dds:vehicle pickup:appt confirmed:${nameplate}`,
        siteSection: "manage",
        hierarchy: `shopping tools:manage:${category}:${nameplate}`,
        userLanguage: "eng",
        pageNameNoVehicle: "fv:manage:dds:vehicle pickup:appt confirmed",
        client: "ford-us",
        site: "fordvehicles.com",
        tool: "event:vehicle pickup complete",
        radUIVersion: "ui:rad:pc",
        variantName: "manageCvdtNext",
      },
      vehicle: {
        modelYear: `${model} ${year}`,
        nameplate: `${nameplate}`,
        bodyModelTrim: `${model}`,
      },
      user: {
        loginStatus: "logged in",
        // errorTracking: "<errorActionName>",
      },
    });
  },
  vehiclePickupAppointmentCancel: (nameplate, category, model, year) => {
    pageLoad({
      page: {
        pageName: `fv:manage:dds:vehicle pickup:cancel:start:${nameplate}`,
        siteSection: "manage",
        hierarchy: `shopping tools:manage:${category}:${nameplate}`,
        userLanguage: "eng",
        pageNameNoVehicle: "fv:manage:dds:vehicle pickup:cancel:start",
        client: "ford-us",
        site: "fordvehicles.com",
        radUIVersion: "ui:rad:pc",
        variantName: "manageCvdtNext",
      },
      vehicle: {
        modelYear: `${model} ${year}`,
        nameplate: `${nameplate}`,
        bodyModelTrim: `${model}`,
      },
      user: {
        loginStatus: "logged in",
        //errorTracking: "<errorActionName>",
      },
    });
  },
  vehiclePickupAppointmentCancelComplete: (
    nameplate,
    category,
    model,
    year
  ) => {
    pageLoad({
      page: {
        pageName: `fv:manage:dds:vehicle pickup:cancel:complete:${nameplate}`,
        siteSection: "manage",
        hierarchy: `shopping tools:manage:${category}:${nameplate}`,
        userLanguage: "eng",
        pageNameNoVehicle: "fv:manage:dds:vehicle pickup:cancel:complete",
        client: "ford-us",
        site: "fordvehicles.com",
        radUIVersion: "ui:rad:pc",
        variantName: "manageCvdtNext",
      },
      vehicle: {
        modelYear: `${year}`,
        nameplate: "auto",
        bodyModelTrim: `${model}`,
      },
      user: {
        loginStatus: "logged in",
        // errorTracking: "<errorActionName>",
      },
    });
  },
  errorpage: (nameplate, category, model, year) => {
    pageLoad({
      page: {
        pageName: `fv:manage:dds:vehicle pickup:error:${nameplate}`,
        siteSection: "manage",
        hierarchy: `shopping tools:manage:${category}:${nameplate}`,
        userLanguage: "eng",
        pageNameNoVehicle: "fv:manage:dds:vehicle pickup:error",
        client: "ford-us",
        site: "fordvehicles.com",
        radUIVersion: "ui:rad:pc",
        variantName: "manageCvdtNext",
      },
      vehicle: {
        modelYear: `${model} ${year}`,
        nameplate: `${nameplate}`,
        bodyModelTrim: `${model}`,
      },
      user: {
        loginStatus: "logged in",
        // errorTracking: "<errorActionName>",
      },
    });
  },
};
