import React from "react";

import PropTypes from "prop-types";
import styled from "styled-components";
import { mediaQueries } from "../common/mediaQueries";
import Icon from "../../components/common/icons/Icon";

const StyledLink = styled.a`
  && {
    font-family: var(--fontPrimary);
    text-align: left;
    color: var(--linkBlue);
    font-weight: 700;
    text-decoration: underline;
    display: block;
    svg {
      vertical-align: middle;
      margin-left: 5px;
    }
    .primary-link-text {
      font-size: var(--fz-sm2);
    }
    :focus {
      outline: 1px solid var(--secondary);
      outline-offset: 8px;
      color: var(--grabber);
    }
    :hover {
      cursor: pointer;
      color: var(--grabber);
    }
    @media ${mediaQueries.underS} {
      font-size: var(--fz-sm2);
    }
  }
`;

const DdsLink = ({ type, link, text, action, ariaLabel, className, icon }) => {
  const linkClassName = className;
  switch (type) {
    case "primary":
      return (
        <StyledLink
          aria-label={ariaLabel}
          onClick={action}
          className={linkClassName}
          href={link || "#"}
          target={link ? "_blank" : ""}
          primary
          tabIndex="0"
        >
          <span className="primary-link-text">{text}</span>
        </StyledLink>
      );
    case "secondary":
      return (
        <StyledLink
          aria-label={ariaLabel}
          className={linkClassName}
          onClick={action}
          href={link}
          target={link ? "_blank" : ""}
          secondary
          tabIndex="0"
        >
          <span className="secondary-link-text">{text}</span>
          {icon && <Icon icon="newFordClose" title="Close Tooltip" />}
        </StyledLink>
      );
    default:
      return (
        <StyledLink
          aria-label={ariaLabel}
          className={className}
          onClick={action}
        >
          <span>{text}</span>
        </StyledLink>
      );
  }
};

DdsLink.propTypes = {
  ariaLabel: PropTypes.string,
  action: PropTypes.func,
  link: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string.isRequired,
};

DdsLink.defaultProps = {
  action: () => console.log("ForwardButton, Default Prop Action Was Clicked"),
  text: "Submit",
  type: "submit",
};

export default DdsLink;
