import { createContext } from "react";

export const AppContext = createContext();

export const AppACTIONS = {
  appointmentStatusUpdated: "APPOINTMENT_STATUS_UPDATED",
  orderIdUpdated: "ORDER_ID_UPDATED",
  currentStepUpdated: "CURRENT_STEP_UPDATED",
  completedStepsUpdated: "COMPLETED_STEPS_UPDATED",
  appointmentInfoUpdated: "APPOINTMENT_INFO_UPDATED",
  accessTokenUpdated: "ACCESS_TOKEN_UPDATED",
  resetData: "RESET_DATA",
};

export const appReducer = (state, action) => {
  switch (action.type) {
    case AppACTIONS.appointmentStatusUpdated:
      console.log(
        `Appointment Status Updated from ${state.appointmentStatus} to ${action.payload}`
      );
      return { ...state, appointmentStatus: action.payload };
    case AppACTIONS.orderIdUpdated:
      console.log(
        `Order Id Updated from ${state.orderId} to ${action.payload}`
      );
      return { ...state, orderId: action.payload };
    case AppACTIONS.currentStepUpdated:
      console.log(
        `Current step Updated from ${state.currentStep} to ${action.payload}`
      );
      return { ...state, currentStep: action.payload };
    case AppACTIONS.completedStepsUpdated:
      console.log(
        `Completed steps Updated from ${state.completedSteps} to ${action.payload}`
      );
      return {
        ...state,
        completedSteps: state.completedSteps.concat(action.payload),
      };
    case AppACTIONS.appointmentInfoUpdated:
      console.log(
        `Appointment info updated from ${state.appointmentInfo} to ${action.payload}`
      );
      return { ...state, appointmentInfo: action.payload };
    case AppACTIONS.accessTokenUpdated:
      console.log(`Access Token Updated: ${action.payload}`);
      return { ...state, accessToken: action.payload };
    case AppACTIONS.resetData:
      console.log("RESET ALL VALUES");
      return {
        ...state,
        appointmentInfo: {},
        currentStep: "",
        completedSteps: [],
      };
    default:
      return state;
  }
};

export const initialAppState = {
  accessToken: "",
  appointmentStatus: "",
  appointmentInfo: {},
  orderId: "",
  currentStep: "",
  completedSteps: [],
};
