import { createContext } from "react";

export const DataContext = createContext();

export const DataACTIONS = {
  appointmentTypeUpdated: "APPOINTMENT_TYPE_UPDATED",
  notesUpdated: "NOTES_UPDATED",
  topicsDetailsUpdated: "TOPICS_DETAILS_UPDATED",
  vehInfoUpdated: "VEH_INFO_UPDATED",
  appointmentBookedUpdated: "APPT_BOOKED_UPDATED",
  edittedUpdated: "EDIITED_UPDATED",
  showLoadingSpinner: "SHOW_LOADING_SPINNER",
};

export const dataReducer = (state, action) => {
  switch (action.type) {
    case DataACTIONS.vehInfoUpdated:
      console.log(
        `Vehicle info updated from ${state.vehInfo} to ${action.payload}`
      );
      return { ...state, vehInfo: action.payload };

    case DataACTIONS.resetData:
      console.log("RESET ALL VALUES");
      return {
        ...initialDataState,
      };
    case DataACTIONS.appointmentTypeUpdated:
      console.log(
        `Appointment type updated from ${state.appointmentType} to ${action.payload}`
      );
      return { ...state, appointmentType: action.payload };
    case DataACTIONS.notesUpdated:
      console.log(`Notes updated from ${state.notes} to ${action.payload}`);
      return { ...state, notes: action.payload };
    case DataACTIONS.topicsDetailsUpdated:
      console.log(
        `Topics array updated from ${state.topicsDetails} to ${action.payload}`
      );
      return { ...state, topicsDetails: action.payload };
    case DataACTIONS.appointmentBookedUpdated:
      console.log(
        `Appointment date time updated from ${state.appointmentBooked} to ${action.payload}`
      );
      return { ...state, appointmentBooked: action.payload };
    case DataACTIONS.edittedUpdated:
      console.log(`editted updated from ${state.editted} to ${action.payload}`);
      return { ...state, editted: action.payload };
    case DataACTIONS.showLoadingSpinner:
      console.log(
        "Show loader update via reducer -New Value:" + action.payload
      );
      return { ...state, showLoadingSpinner: action.payload };
    default:
      return state;
  }
};

export const initialDataState = {
  vehInfo: {},
  appointmentType: "",
  appointmentBooked: "",
  notes: "",
  topicsDetails: null,
  editted: false,
};
