import React, { useState, useContext, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import styled from "styled-components";
import TimeRadioButtons from "./common/TimeRadioButtons";
import DdsButton from "./common/DdsButton";
import { useNavigate } from "react-router-dom";
import StepHeader from "../components/common/StepHeader";
import { StepHeaders } from "../components/common/HeaderCopy";
import ErrorPage from "./ErrorPage";

import {
  DataContext,
  DataACTIONS,
} from "../components/common/context/DataContext";
import {
  AppContext,
  AppACTIONS,
} from "../components/common/context/AppContext";
import { convertTime, convertDateTimeToISO } from "../helpers/util";

import prevIcon from "../assets/img/calendar-prev.svg";
import nextIcon from "../assets/img/calendar-next.svg";

import {
  pageLoadTags,
  onClick,
} from "../components/common/utils/analytics/index";

const StyledAppointmentPage = styled.div`
  && {
    .react-calendar {
      font-family: var(--fontPrimary);
      border: 0;
      margin: auto;
      button {
        border: 0;
        font-family: var(--fontPrimary);
        font-size: var(--fz-md);
      }
      .react-calendar__navigation__arrow {
        color: transparent;
        &.react-calendar__navigation__prev-button {
          background: url(${prevIcon}) no-repeat center;
        }
        &.react-calendar__navigation__next-button {
          background: url(${nextIcon}) no-repeat center;
        }
      }

      .react-calendar__month-view__weekdays {
        border: 1px solid var(--fordGrey);
        border-radius: 20px;
        abbr[title] {
          text-decoration: none;
        }
      }
      .react-calendar__month-view__days {
        button.react-calendar__tile {
          line-height: 24px;
          color: black;
          abbr {
            border-radius: 8px;
            padding: 5px 10px;
          }
          &:disabled {
            background: white;
          }
          &:not(:disabled) {
            abbr {
              color: var(--accentBlue);
              font-weight: bold;
            }
          }
          &.react-calendar__tile--now {
            background: white;

            abbr {
              background: none;
              border: 2px solid #000;
            }
          }
          &.react-calendar__tile--active {
            background: white;
            abbr {
              border: 2px solid var(--accentBlue);
              background: var(--accentBlue);
              color: white;
            }
          }
        }
      }
      .react-calendar__navigation__prev2-button,
      .react-calendar__navigation__next2-button {
        display: none;
      }
      .react-calendar__navigation__label__labelText {
        color: var(--fordBlue);
        font-weight: bold;
      }
    }
  }
`;

function AppointmentsComp() {
  const { dataState, dataDispatch } = useContext(DataContext);
  const { appDispatch } = useContext(AppContext);
  let navigate = useNavigate();
  const [times, setTimes] = useState([]);
  const { model, year } = dataState.vehInfo;
  const nameplate =
    model === "Mustang Mach-E" ? "ford mach-e" : "ford f-150 lightning";
  const category = model === "Mustang Mach-E" ? "suv" : "lightning";
  const [validDates, setValidDates] = useState([]);
  const [selectedTime, setSelectedTime] = useState(() => {
    let time = "";
    if (dataState.appointmentBooked) {
      time = convertTime(dataState.appointmentBooked);
    }
    return time;
  });

  const [selectedDate, setSelectedDate] = useState(() => {
    let today = "";
    getValidDates();
    if (dataState.appointmentBooked) {
      if (validDates.includes(formatDate(dataState.appointmentBooked))) {
        today = dataState.appointmentBooked;
      } else {
        // timezone conversion for CST
        today = new Date(validDates[0] + " CST");
      }
    } else {
      today = new Date(validDates[0] + " CST");
    }
    if (dataState.vehInfo.upcomingAppointmentSlots) {
      for (const [key, value] of Object.entries(
        dataState.vehInfo.upcomingAppointmentSlots
      )) {
        if (key === formatDate(today)) {
          mapHoursToTimes(value);
        }
      }
    } else {
      appDispatch({
        type: AppACTIONS.appointmentStatusUpdated,
        payload: "ERROR",
      });
      navigate("/error");
    }

    return today;
  });

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  let dealerHours = "";
  let closedDay = 0;

  if (dataState.vehInfo.dealerHours) {
    dealerHours = dataState.vehInfo.dealerHours;
    closedDay = getClosedDay(dealerHours);
  }

  function getValidDates() {
    if (dataState.vehInfo.upcomingAppointmentSlots) {
      // get length of valid appointment slots
      const validApptSlots = JSON.parse(
        JSON.stringify(dataState.vehInfo.upcomingAppointmentSlots),
        (key, value) => {
          if (Array.isArray(value) && value.length === 0) {
            return undefined;
          }
          return value;
        }
      );

      const validApptSlotsLenth = Object.keys(validApptSlots).length;

      if (validDates.length !== validApptSlotsLenth) {
        for (const [key, value] of Object.entries(
          dataState.vehInfo.upcomingAppointmentSlots
        )) {
          if (value.length > 0) {
            validDates.push(key);
          }
        }
      }
      setValidDates(validDates.sort((a, b) => new Date(a) - new Date(b)));
    } else {
      appDispatch({
        type: AppACTIONS.appointmentStatusUpdated,
        payload: "ERROR",
      });
      navigate("/error");
    }
  }

  useEffect(() => {
    pageLoadTags.vehiclePickupDateTime(nameplate, category, model, year);
    setValidDates([]);
    getValidDates();
    appDispatch({
      type: AppACTIONS.currentStepUpdated,
      payload: 2,
    });
  }, [appDispatch]);

  function getClosedDay(salesHours) {
    if (salesHours.length) {
      for (let i = 0; i < salesHours.length; i++) {
        if (salesHours[i].isClosed) {
          return salesHours[i].day;
        }
      }
    } else {
      return;
    }
  }

  const tileDisabled = ({ date }) => {
    if (validDates.includes(formatDate(date))) {
      return false;
    }
    return true;
  };

  function mapHoursToTimes(value) {
    const times = [];
    if (value.length > 0) {
      value.forEach(function (item, index) {
        times.push({
          label: item,
          value: item,
        });
      });
      setTimes(times);
      return times;
    }
    return times;
  }

  const handleTimeChange = (value) => {
    setSelectedTime(value);
  };

  const selectedDateChange = (date) => {
    setTimes([]);
    setSelectedTime("");
    for (const [key, value] of Object.entries(
      dataState.vehInfo.upcomingAppointmentSlots
    )) {
      if (key === formatDate(date)) {
        mapHoursToTimes(value);
      }
    }
  };

  function handleSubmit(isSave) {
    const dateTimeIso = convertDateTimeToISO(selectedTime, selectedDate);

    dataDispatch({
      type: DataACTIONS.appointmentBookedUpdated,
      payload: dateTimeIso,
    });
    if (isSave) {
      onClick.vehiclePickupReviewDetails(
        nameplate,
        year,
        model,
        "edit appt details"
      );
    } else {
      onClick.vehiclePickupDateTime(nameplate, year, model, "continue");
    }

    if (!dataState.editted)
      appDispatch({
        type: AppACTIONS.completedStepsUpdated,
        payload: 2,
      });
    navigate("/summary");
  }
  function handleBack() {
    onClick.vehiclePickupDateTime(nameplate, year, model, "back");
    navigate("/");
  }

  return (
    <React.Fragment>
      {dealerHours.length > 0 ? (
        <StyledAppointmentPage>
          <div>
            <StepHeader
              mainHead={StepHeaders.appointment.main}
              subHead={StepHeaders.appointment.sub}
            />
            <Calendar
              onChange={setSelectedDate}
              onClickDay={selectedDateChange}
              value={selectedDate}
              calendarType="US"
              tileDisabled={tileDisabled}
            />
            <TimeRadioButtons
              times={times}
              selectedTime={selectedTime}
              onTimeChange={handleTimeChange}
            />
            {dataState.editted === true ? (
              <div className="dds-edit-buttons-container">
                <DdsButton
                  type="primary"
                  text="Save"
                  action={() => handleSubmit(true)}
                />
              </div>
            ) : (
              <div className="dds-buttons-container">
                <DdsButton
                  type="secondary"
                  text="Back"
                  action={handleBack}
                  iconType="previousIcon"
                />
                <DdsButton
                  type={selectedTime ? "primary" : "disabled"}
                  text="Continue"
                  action={() => handleSubmit(false)}
                  iconType="nextIcon"
                />
              </div>
            )}
          </div>
        </StyledAppointmentPage>
      ) : (
        <ErrorPage />
      )}
    </React.Fragment>
  );
}

export default AppointmentsComp;
