export const APPOINTMENT_TYPE = [
  { name: "Guided drive", value: "GUIDED_DRIVE" },
  { name: "New owner overview", value: "NEW_OWNER" },
  { name: "Just hand me the keys", value: "JUST_KEYS" },
];
export const TOPICS = ["Home charging", "Public charging", "Towing"];
const qaUrls = ["smk2-", "nqa-"];
const uatUrls = ["uat-", "nstg", "npp"];

export const EXIT_URL =
  "https://sandbox.experience.lifecycle-management.products.dev.ecommerce.ford.com/manage?orderId=";

const currentHostname = window.location.hostname;

const qaExitUrl =
  "https://wwwqa.ecommerce.ford.com/order-details/manage?orderId=";
const uatExitUrl = "https://wwwqa2.ford.com/order-details/manage?orderId=";
const prodExitUrl = "https://www.ford.com/order-details/manage?orderId=";

export const getExitUrl = (orderId) => {
  if (currentHostname.includes("dev")) {
    return qaExitUrl;
  } else if (currentHostname.includes("qa")) {
    if (checkPrefix(qaUrls, orderId)) {
      return qaExitUrl;
    } else if (checkPrefix(uatUrls, orderId)) {
      return uatExitUrl;
    } else {
      return qaExitUrl;
    }
  } else {
    return prodExitUrl;
  }
};
const checkPrefix = (array, prefix) => {
  let match = false;
  array.forEach(function (element) {
    if (prefix.includes(element)) {
      match = true;
    }
  });
  return match;
};
