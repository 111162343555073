import axios from "axios";

class Api {
  constructor() {
    const currentHostname = window.location.hostname;
    const prodDDSUrl = "https://deliveryday.ford.com/ddapi/v1/";
    const qaDDSUrl = "https://deliveryday-qa.ford.com/ddapi/v1/";
    const devDDSUrl = "https://deliveryday-dev.ford.com/ddapi/v1/";
    this.getBASE_URL = () => {
      let ddsBaseUrl;
      if (currentHostname.includes("deliveryday.ford")) {
        ddsBaseUrl = prodDDSUrl;
      } else if (currentHostname.includes("qa")) {
        ddsBaseUrl = qaDDSUrl;
      } else {
        ddsBaseUrl = devDDSUrl;
      }
      console.log(ddsBaseUrl);
      // return `http://localhost:8080`
      return ddsBaseUrl;
    };

    this.GET_ORDERS_URL = "order/";
    this.CREATE_APPOINTMENT_URl = "appointment";
    this.DELETE_APPOINTMENT_URl = "appointment/";
    this.UPDATE_APPOINTMENT_URl = "appointment/";

    this.AXIOS = axios.create();

    this.AXIOS.defaults.headers.post["Content-Type"] = "application/json";
    this.AXIOS.defaults.headers.delete["Content-Type"] = "application/json";
  }

  async getOrders(orderNumber, token) {
    const headers = { "cat-auth": `${token}` };
    const response = await this.AXIOS.get(
      `${this.getBASE_URL()}${this.GET_ORDERS_URL}${orderNumber}`,
      { headers }
    );

    return response.data;
  }
  async createAppointment(body, token) {
    const headers = { "cat-auth": `${token}` };
    const response = await this.AXIOS.post(
      `${this.getBASE_URL()}${this.CREATE_APPOINTMENT_URl}`,
      body,
      { headers }
    );
    return response.data;
  }
  async deleteAppointment(appointmentId, token) {
    // const headers = { Authorization: `Bearer ${token}` };
    const headers = { "cat-auth": `${token}` };
    const response = await this.AXIOS.delete(
      `${this.getBASE_URL()}${this.DELETE_APPOINTMENT_URl}${appointmentId}`,
      { headers }
    );
    return response.data;
  }

  async updateAppointment(body, apptId, token) {
    const headers = { "cat-auth": `${token}` };
    const response = await this.AXIOS.put(
      `${this.getBASE_URL()}${this.UPDATE_APPOINTMENT_URl}${apptId}`,
      body,
      { headers }
    );
    return response.data;
  }
}

export default new Api();
