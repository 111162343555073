export const topicsObj = [
  {
    name: "Guided drive",
    subtitle:
      "I'd like a Ford EV Specialist to review specific features and join me on my first drive.",
  },
  {
    name: "New owner overview",
    subtitle: "I'd like a brief overview and have some questions answered.",
  },
  {
    name: "Just hand me the keys",
    subtitle: "I'd like to experience my new vehicle on my own.",
  },
];
export const topicsDetailsObj = [
  {
    name: "Home charging",
    subtitle: "Learn about daily life with an electric vehicle.",
    initialValue: false,
    isChecked: false,
    appointType: "",
    enumVal: "HOME_CHARGING",
  },
  {
    name: "Public charging",
    subtitle: "See how to make on-the-road charging part of your travel plans.",
    initialValue: false,
    isChecked: false,
    appointType: "",
    enumVal: "PUBLIC_CHARGING",
  },
  {
    name: "Towing",
    subtitle: "Explore the features that help you hitch up with confidence.",
    initialValue: false,
    isChecked: false,
    appointType: "",
    enumVal: "TOWING",
  },
];
