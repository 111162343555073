import { useEffect } from "react";

const ScrollToTop = () => {
  useEffect(() => {
    const container = document.querySelector(".dds-app-wrapper-container");
    if (container) container.scrollIntoView();
  }, []);
  return null;
};
export default ScrollToTop;
