import React from "react";

const colors = {
  fordBlue: "#102b4e",
  mediumBlue: "#003478",
  lightBlue: "#1a3b7d",
  fordSlate: "#4d4d4d",
  red: "#ff0000",
  ctaCyan: "#2ca3e1",
  warmTaupe: "#c4bcb7",
  white: "#ffffff",
  bgGrey: "#f7f7f7",
};
// svgs that have functionality associated with them and/or replace an image need titles
const accesibilityWarning = (name) => (
  <span className="dds-error-message">
    The {name} icon requires a 'title' prop
    <a href="https://www.w3.org/TR/SVG-access/#Graphics">for accessibility</a>
  </span>
);

const getIcon = ({ icon, id, title }) => {
  switch (icon) {
    case "calender":
      return (
        <svg
          className="calendar-icon"
          id={id}
          width="20"
          height="20"
          viewBox="0 0 24 24"
        >
          <path d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z" />
        </svg>
      );
    case "newFordClose":
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.115 0L16 1.88496L9.886 8L16 14.115L14.115 16L8 9.886L1.88496 16L0 14.115L6.114 8L0 1.88496L1.88496 0L8 6.114L14.115 0Z"
            fill="black"
          />
        </svg>
      );

    case "close":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="10"
          viewBox="0 0 24 24"
        >
          <path
            fill="#00095B"
            d="M1.7.293l10.292 10.292L22.285.293c.36-.36.928-.388 1.32-.083l.094.083c.39.39.39 1.024 0 1.414L13.407 12 23.7 22.293c.39.39.39 1.024 0 1.414-.39.39-1.023.39-1.414 0L11.992 13.415 1.7 23.707c-.36.36-.927.388-1.32.083l-.094-.083c-.39-.39-.39-1.024 0-1.414L10.577 12 .285 1.707c-.39-.39-.39-1.024 0-1.414.39-.39 1.024-.39 1.414 0z"
          />
        </svg>
      );
    case "chevronDown":
      return (
        <svg
          className="chevron-down-icon"
          id={id}
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
        >
          <path
            fill={colors.fordBlue}
            d="M15.157 4.136c.201-.19.518-.18.707.021.19.201.18.518-.021.707l-7.44 7c-.191.18-.49.181-.682.003l-7.56-7c-.203-.188-.215-.504-.028-.707.188-.202.504-.214.707-.027l7.218 6.683 7.1-6.68z"
          />
        </svg>
      );
    case "chevronUp":
      return (
        <svg
          className="chevron-up-icon"
          id={id}
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
        >
          <path
            fill={colors.fordBlue}
            d="M15.157 11.864c.201.19.518.18.707-.021.19-.201.18-.518-.021-.707l-7.44-7c-.191-.18-.49-.181-.682-.003l-7.56 7c-.203.188-.215.504-.028.707.188.202.504.214.707.027l7.218-6.683 7.1 6.68z"
          />
        </svg>
      );
    case "error":
      return (
        <svg
          className="error-icon"
          id={id}
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 14 14"
        >
          <path
            fillRule="evenodd"
            d="M7 0c.203 0 .377.106.48.264l.036.06 6.417 12.834c.04.079.067.165.067.259 0 .299-.225.545-.515.58l-.068.003H.583C.261 14 0 13.739 0 13.417c0-.07.015-.137.04-.198l.027-.06L6.484.324C6.579.135 6.772 0 7 0zm0 1.888L1.527 12.833h10.946L7 1.888zM7 10.5c.322 0 .583.261.583.583 0 .322-.26.583-.583.583-.322 0-.583-.26-.583-.583 0-.322.26-.583.583-.583zm0-5.25c.322 0 .583.261.583.583v3.5c0 .322-.26.583-.583.583-.322 0-.583-.26-.583-.583v-3.5c0-.322.26-.583.583-.583z"
          />
        </svg>
      );
    case "iBall":
      return !title ? (
        accesibilityWarning("iBall")
      ) : (
        <svg
          className="iBall-icon"
          id={id}
          width="20"
          height="20"
          viewBox="0 0 20 20"
        >
          {title && <title>{title}</title>}
          <path d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0zm0 1.667a8.333 8.333 0 1 0 0 16.666 8.333 8.333 0 0 0 0-16.666zM10 7.5c.425 0 .775.318.827.729l.006.104v5.834a.833.833 0 0 1-1.66.104l-.006-.104V8.333c0-.46.373-.833.833-.833zM10 5a.833.833 0 1 1 0 1.667A.833.833 0 0 1 10 5z" />
        </svg>
      );
    case "leftArrow":
      return (
        <svg
          className="left-arrow-icon"
          id={id}
          viewBox="0 0 24 24"
          width="16px"
          height="16px"
        >
          <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
        </svg>
      );
    case "location":
      return (
        <svg
          className="location-icon"
          id={id}
          width="40px"
          height="40px"
          viewBox="0 0 40 40"
          version="1.1"
        >
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-542.000000, -413.000000)" fill="#1B394E">
              <path d="M562,413.816327 C572.577959,413.816327 581.183673,422.422041 581.183673,433 C581.183673,443.577959 572.577959,452.183673 562,452.183673 C551.422041,452.183673 542.816327,443.577959 542.816327,433 C542.816327,422.422041 551.422041,413.816327 562,413.816327 L562,413.816327 L562,413.816327 Z M562,413 C550.955918,413 542,421.954286 542,433 C542,444.045714 550.955918,453 562,453 C573.045714,453 582,444.045714 582,433 C582,421.954286 573.045714,413 562,413 L562,413 Z M567.2968,425.5376 C564.4472,422.6896 559.784,422.6896 556.936,425.5376 C554.496,427.9776 554.152,431.7456 555.888,434.5712 L562.1208,443.0744 L568.3448,434.5712 C570.0808,431.7464 569.7352,427.9784 567.2968,425.5376 L567.2968,425.5376 Z M564.264,432.8656 C563.0808,434.0488 561.152,434.0488 559.968,432.8656 C558.784,431.6896 558.784,429.7536 559.968,428.5696 C561.152,427.3936 563.0808,427.3936 564.264,428.5696 C565.4496,429.7536 565.4496,431.6896 564.264,432.8656 L564.264,432.8656 Z" />
            </g>
          </g>
        </svg>
      );
    case "phone":
      return (
        <svg
          className="phone-icon"
          id={id}
          width="40"
          height="40"
          viewBox="0 0 40 40"
        >
          <path
            fill="#1B394E"
            fillRule="evenodd"
            d="M20 .8C30.586.8 39.2 9.414 39.2 20c0 10.586-8.614 19.2-19.2 19.2C9.413 39.2.8 30.586.8 20 .8 9.414 9.413.8 20 .8zm0-.8c11.046 0 20 8.954 20 20s-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0zm-6.405 10.57c-.842.42-1.838.848-2.473 1.272-1.77 1.176-.277 8.106 4.369 12.924l-.004.003c5.206 5.298 12.29 6.666 13.46 4.905.423-.635.867-1.616 1.287-2.458.397-.798.055-1.788-.741-2.19l-4.048-2.048c-.414-.277-.998-.184-1.275.231l-1.007 1.48c-3.256-1.039-6.004-3.758-7.056-7.06l1.462-1.024c.415-.277.513-.857.236-1.271l-2.04-4.04c-.4-.797-1.372-1.122-2.17-.724z"
          />
        </svg>
      );
    case "rightArrow":
      return (
        <svg
          className="right-arrow-icon"
          id={id}
          viewBox="0 0 24 24"
          width="16px"
          height="16px"
        >
          <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
        </svg>
      );
    case "shape":
      return (
        <svg
          className="shape-icon"
          id={id}
          width="17"
          height="21"
          viewBox="0 0 17 21"
        >
          <path
            fill="#0E2A4F"
            d="M16.992 4.601c0-.454.018-.913 0-1.367V3.22c0-.239-.165-.42-.385-.495l-2.72-.923C12.458 1.316 11.032.829 9.602.345 9.286.237 8.972.114 8.65.02c-.16-.046-.27-.01-.409.036-.162.057-.327.11-.49.167L3.648 1.616.438 2.706c-.018.005-.034.013-.052.018-.22.074-.385.259-.385.495V7.97c0 1-.002 2 0 3 .003.85.155 1.703.443 2.503.588 1.649 1.66 3.097 2.934 4.305 1.259 1.192 2.733 2.19 4.314 2.923.18.082.36.162.541.239l.034.015h.003l.081.028c.126.034.265.016.403-.04.003 0 .006-.003.006-.003 1.596-.664 3.078-1.603 4.376-2.729 1.309-1.135 2.463-2.518 3.154-4.1.356-.818.592-1.664.67-2.55.042-.475.027-.955.027-1.432V4.601h.005zM13.144 7.75l-.652.638-2.824 2.767-1.785 1.749c-.301.295-.812.295-1.11 0l-.361-.354-2.532-2.48c-.311-.305-.288-.78 0-1.087.288-.305.82-.284 1.11 0l.361.357c.658.643 1.315 1.292 1.972 1.935l.099-.097 2.824-2.77 1.786-1.748c.311-.305.798-.282 1.11 0 .314.282.293.805.002 1.09z"
          />
        </svg>
      );
    case "checkMark":
      return (
        <svg
          className="checkmark-icon"
          id={id}
          fill="#000000"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 50 50"
          width="20px"
          height="20px"
        >
          <path
            fill="#fff"
            d="M 42.875 8.625 C 42.84375 8.632813 42.8125 8.644531 42.78125 8.65625 C 42.519531 8.722656 42.292969 8.890625 42.15625 9.125 L 21.71875 40.8125 L 7.65625 28.125 C 7.410156 27.8125 7 27.675781 6.613281 27.777344 C 6.226563 27.878906 5.941406 28.203125 5.882813 28.597656 C 5.824219 28.992188 6.003906 29.382813 6.34375 29.59375 L 21.25 43.09375 C 21.46875 43.285156 21.761719 43.371094 22.050781 43.328125 C 22.339844 43.285156 22.59375 43.121094 22.75 42.875 L 43.84375 10.1875 C 44.074219 9.859375 44.085938 9.425781 43.875 9.085938 C 43.664063 8.746094 43.269531 8.566406 42.875 8.625 Z"
          />
        </svg>
      );
    case "circleCheck":
      return (
        <svg
          className="checkmark"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 52 52"
        >
          <circle
            className="checkmark__circle"
            cx="26"
            cy="26"
            r="25"
            fill="none"
          />
          <path
            className="checkmark__check"
            fill="none"
            d="M14.1 27.2l7.1 7.2 16.7-16.8"
          />
        </svg>
      );
    case "circleDash":
      return (
        <svg height="20" width="20">
          <circle
            cx="12"
            cy="12"
            r="6"
            stroke="#102b4e"
            troke-width="1.3"
            fill="white"
          />
        </svg>
      );
    case "financeIcon":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="73"
          height="58"
          viewBox="0 0 73 58"
        >
          <path
            fill="#102B4E"
            d="M68.514 53.887C70.961 53.887 73 55.943 73 58H0c0-2.057 1.631-4.113 4.486-4.113h64.028zm0-33.32c0 2.468-2.447 4.114-4.486 4.114V45.66c2.039 0 4.486 2.056 4.486 4.113H54.648c0-2.057 2.447-4.113 4.486-4.113V24.68c-2.039 0-4.486-1.645-4.486-4.113zm-50.162 0c0 2.468-2.447 4.114-4.486 4.114V45.66c2.039 0 4.486 2.056 4.486 4.113H4.486c0-2.057 1.631-4.113 4.486-4.113V24.68c-2.855 0-4.486-1.645-4.486-4.113zm19.983-7.815v4.113h4.894v3.702h-6.933c-2.447 0-4.894 2.057-4.894 4.114 0 2.88 2.447 4.113 4.894 4.113 4.894 0 8.972 3.702 8.972 8.639 0 4.113-2.855 6.993-6.933 8.227v4.113H33.85V45.66h-4.486v-4.114h6.933c2.855 0 4.486-1.645 4.486-4.113 0-2.057-1.631-4.525-4.486-4.525-4.894 0-8.564-3.291-8.564-8.227 0-3.702 2.855-6.582 6.117-7.816v-4.113h4.486zM36.296 0L73 14.397c-1.223 2.057-3.263 2.88-6.117 2.057L36.296 4.524 5.71 16.455c-2.446.823-4.486 0-5.709-2.057z"
          />
        </svg>
      );
    case "leaseIcon":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="55"
          height="52"
          viewBox="0 0 55 52"
        >
          <path
            fill="#102B4E"
            d="M39.041 0c2.877 0 5.754 1.646 6.986 4.116l2.877 8.643h1.233c2.466 0 4.52 1.647 4.52 4.117h-4.52v4.056c-1.386-.94-2.904-1.7-4.52-2.246v-1.81l-4.11-11.525c0-.412-1.233-1.235-2.466-1.235H14.795c-.411 0-1.644.823-1.644 1.235L9.04 16.876v3.704h4.932v4.117H9.04v4.116h13.371c-.586 1.3-1.029 2.678-1.309 4.115h-7.13v2.059c0 1.235-1.233 2.47-2.466 2.47h-4.52c-2.055 0-2.466-1.235-2.466-2.47V16.876H0c0-2.47 1.644-4.116 4.52-4.116h1.233L8.63 4.116C9.863 1.646 11.918 0 14.795 0H39.04zm-1.644 8.644l3.699 8.232H13.973l2.876-8.232h20.548zm2.02 12.485C48.053 21.13 55 28.046 55 36.565 55 45.083 48.053 52 39.417 52c-8.47 0-15.239-6.921-15.239-15.435s6.77-15.436 15.239-15.436zm0 3.95c-6.243 0-11.256 5.127-11.256 11.486 0 6.359 5.013 11.485 11.256 11.485 6.433 0 11.6-5.145 11.6-11.485 0-6.341-5.167-11.486-11.6-11.486zm-.244 1.643c.875 0 1.811.634 1.99 1.39l.03.257v7.147l3.389 2.533c.924.776 1 1.833.136 2.492l-.26.17-.005-.01-.1.062c-.532.237-1.464.24-1.975.02l-.293-.186-3.63-2.714c-.61-.512-.98-1.11-1.074-1.687l-.024-.287v-7.54c0-.846.834-1.647 1.816-1.647z"
          />
        </svg>
      );
    case "ownedIcon":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="55"
          height="53"
          viewBox="0 0 55 53"
        >
          <path
            fill="#102B4E"
            d="M54.897 46.83L55 53H27.603v-6.17c0-4.68 4.636-8.57 9.745-8.57h8.294c4.872-.164 8.982 3.64 9.255 8.57zm-9.255-4.285h-8.294c-2.77 0-5.166 1.935-5.439 3.914l-.025.37v1.884h18.763l-.025-1.646c-.135-2.46-2.054-4.373-4.513-4.516l-.467-.006zM39.042 0c2.876 0 5.753 1.645 6.985 4.113l2.877 8.639h1.233c2.466 0 4.52 1.645 4.52 4.113h-4.52v4.576c-1.218-1.29-2.768-2.308-4.52-2.957v-1.619l-4.11-11.518c0-.41-1.233-1.234-2.466-1.234H14.795c-.411 0-1.644.823-1.644 1.234L9.04 16.865v3.702h4.932v4.114H9.04v4.113h20.283c.064 1.477.453 2.866 1.1 4.114H13.973v2.056c0 1.234-1.233 2.468-2.466 2.468h-4.52c-2.055 0-2.466-1.234-2.466-2.468V16.866H0c0-2.469 1.644-4.114 4.52-4.114h1.233L8.63 4.113C9.863 1.645 11.918 0 14.795 0H39.04zm2.088 21.116c4.634 0 8.39 3.246 8.39 7.25s-3.756 7.25-8.39 7.25-8.39-3.246-8.39-7.25 3.756-7.25 8.39-7.25zm0 3.428c-2.845 0-4.966 1.832-4.966 3.822s2.12 3.822 4.966 3.822c2.845 0 4.966-1.833 4.966-3.822 0-1.99-2.12-3.822-4.966-3.822zM37.397 8.638l3.699 8.227H13.973l2.75-7.864.126-.363h20.548z"
          />
        </svg>
      );
    case "lincolnError":
      return (
        <svg
          width="24"
          height="24"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path
            d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"
            fill="#B00020"
            fillRule="evenodd"
          />
        </svg>
      );
    case "lincolnPlus":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="16"
          viewBox="0 0 17 16"
        >
          <g fill="none" fill-rule="evenodd">
            <g fill="#FFF" fill-rule="nonzero">
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <path
                          d="M8 0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8 3.582-8 8-8zm0 1.333C4.318 1.333 1.333 4.318 1.333 8S4.318 14.667 8 14.667 14.667 11.682 14.667 8 11.682 1.333 8 1.333zM8 4c.368 0 .667.298.667.667l-.001 2.666h2.667c.369 0 .667.299.667.667 0 .368-.298.667-.667.667l-2.667-.001v2.667c0 .369-.298.667-.666.667-.368 0-.667-.298-.667-.667V8.666H4.667C4.298 8.667 4 8.369 4 8c0-.368.298-.667.667-.667h2.666V4.667C7.333 4.298 7.632 4 8 4z"
                          transform="translate(-692 -1643) translate(530 1340) translate(18 159) translate(46 132) translate(35 12) translate(.5) translate(62.707)"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      );
    case "nextIcon":
      return (
        <svg
          width="16px"
          height="16px"
          viewBox="0 0 10 16"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Caret-Right</title>
          <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Desktop-HD"
              transform="translate(-217.000000, -160.000000)"
              fill="#FFFFFF"
            >
              <g id="Caret-Right" transform="translate(217.290000, 160.000000)">
                <polygon
                  id="CaretRight"
                  points="6.58789322 8.00210678 0 14.59 1.41421356 16.0042136 9.41632034 8.00210678 1.41421356 0 4.21884749e-15 1.41421356"
                />
              </g>
            </g>
          </g>
        </svg>
      );
    case "previousIcon":
      return (
        <svg
          width="16px"
          height="16px"
          viewBox="0 0 10 16"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Caret-Left</title>
          <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Desktop-HD"
              transform="translate(-174.000000, -160.000000)"
              fill="#FFFFFF"
            >
              <g id="Caret-Left" transform="translate(174.290000, 160.000000)">
                <polygon
                  id="CaretLeft"
                  transform="translate(4.708160, 8.002107) scale(-1, 1) translate(-4.708160, -8.002107) "
                  points="6.58789322 8.00210678 0 14.59 1.41421356 16.0042136 9.41632034 8.00210678 1.41421356 0 4.21884749e-15 1.41421356"
                />
              </g>
            </g>
          </g>
        </svg>
      );
    case "addIcon":
      return (
        <svg
          width="16px"
          height="16px"
          viewBox="0 0 16 16"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>IncreaseAdd</title>
          <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Desktop-HD"
              transform="translate(-253.000000, -160.000000)"
              fill="#FFFFFF"
            >
              <g id="IncreaseAdd" transform="translate(253.000000, 160.000000)">
                <path
                  d="M9,0 L9,6.851 L16,6.85107422 L16,8.85107422 L9,8.851 L9,16 L7,16 L7,8.851 L0,8.85107422 L0,6.85107422 L7,6.851 L7,0 L9,0 Z"
                  id="IncreaseAddPrimary"
                />
              </g>
            </g>
          </g>
        </svg>
      );
    case "removeIcon":
      return (
        <svg
          width="16px"
          height="16px"
          viewBox="0 0 12 12"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>CloseRemove</title>
          <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Desktop-HD"
              transform="translate(-297.000000, -162.000000)"
              fill="#FFFFFF"
            >
              <g id="CloseRemove" transform="translate(297.000000, 162.000000)">
                <polygon points="10.59 0 12.0042136 1.41421356 7.41710678 6.00210678 12.0042136 10.59 10.59 12.0042136 6.00210678 7.41710678 1.41421356 12.0042136 0 10.59 4.58710678 6.00210678 0 1.41421356 1.41421356 0 6.00210678 4.58710678" />
              </g>
            </g>
          </g>
        </svg>
      );

    case "editIcon":
      return (
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            stroke="#FFF"
            fill="none"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="m15 5.143-10 10M12 2.143l-10 10M5 15.143l-4 1 1-4zM15 5.143c1.334-1.333 1.501-2.5.5-3.5s-2.168-.834-3.5.5l3 3z" />
          </g>
        </svg>
      );
    default:
      return "";
  }
};
export default getIcon;
