import { useState } from 'react'

export const useInput = (item) => {
  const {
    initialValue,
    length,
    maxLength,
    minLength,
    invalidCharacters,
    validation,
    filter,
    ariaLabel
  } = item
  const [value, setValue] = useState(initialValue)
  const [displayValue, setDisplayValue] = useState(initialValue)
  const [valid, setValid] = useState(false)
  const [errorMessage, setErrorMessage] = useState()

  function onChange(e) {
    let newValue = e.target.value.replace(invalidCharacters, '')

    if (!newValue) e.target.value = ''
    if (length) newValue = newValue.slice(0, length.value)

    validate(newValue)
    setValues(newValue)
  }

  function setValues(value) {
    setValue(value)
    const newValue = filter ? filter(value) : value

    setDisplayValue(newValue)
  }

  function onSelect(e) {
    const newValue = e
    setValid(true)
    setValue(newValue)
  }

  function validate(value) {
    setErrorMessage(null)
    setValid(true)
    let lengthMessage

    if (!value || value.length === 0) {
      setValid(false)
      setErrorMessage('Please enter a value')
    }
    if (length || maxLength || minLength) {
      let lengthValid = true
      if (length) {
        lengthValid = value.length === length.value
        lengthMessage = length.errorMessage
      } else if (minLength) {
        lengthValid = value.length >= minLength.value
        lengthMessage = minLength.errorMessage
      } else if (maxLength) {
        lengthValid = value.length <= maxLength.value
        lengthMessage = maxLength.errorMessage
      }
      if (!lengthValid) {
        setValid(false)
        setErrorMessage(lengthMessage)
      }
    }
    if (validation) {
      const validValue = validation.value.test(value)

      if (!validValue) {
        setValid(false)
        if (validation.type === 'vin') {
          if (value.length !== 17) {
            setErrorMessage('VIN must be 17 characters ')
          } else {
            setErrorMessage(validation.errorMessage)
          }
        }
      }
    }
  }

  return {
    ariaLabel,
    value,
    displayValue,
    onChange,
    onSelect,
    valid,
    setValid,
    errorMessage,
    ...item
  }
}

export const UseRadio = (item) => {
  const { label, options, dataAction, initialValue } = item
  const [value, setValue] = useState(initialValue)

  function onChange(e, target) {
    if (target) {
      setValue(e.value)
    } else {
      setValue(e.target.value)
    }
  }
  return {
    dataAction,
    initialValue,
    label,
    options,
    value,
    onChange,
    ...item
  }
}
