import { createGlobalStyle } from "styled-components";

import FordMotionReg from "../../assets/fonts/Ford/FordMotion/FordMotion-Regular.ttf";
import FordMotionBold from "../../assets/fonts/Ford/FordMotion/FordMotion-Bold.ttf";
import FordMotionMed from "../../assets/fonts/Ford/FordMotion/FordMotion-Medium.ttf";

const GlobalFont = createGlobalStyle`
    /* FordMotion */
    @font-face {
        font-family: "FordMotion";
        src: url(${FordMotionReg}) format('truetype');
        font-weight: normal;
    }
    @font-face {
        font-family: "FordMotion";
        src: url(${FordMotionMed}) format('truetype');
        font-weight: 500;
    }
     @font-face {
        font-family: "FordMotion";
        src: url(${FordMotionBold}) format('truetype');
        font-weight: 700;
    }
`;
export default GlobalFont;
