import React, { useContext, useState, useEffect } from "react";

import Checkbox from "./Checkbox";
import { DataContext } from "../common/context/DataContext";
import { StyledRadioButtonCard } from "../styles/StyledRadioButtonCard";

const RadioButtonCard = ({
  name,
  ariaLabels,
  id,
  index,
  subtitle,
  setChecked,
  curValue,
  onChange,
  topicsDetailsObj,
  handleStandardTopics,
}) => {
  const [click, setClicked] = useState(false);
  const { dataState } = useContext(DataContext);
  function clearCheckboxes(name) {
    topicsDetailsObj.forEach((val) => {
      if (val.appointType !== name) {
        val.isChecked = false;
      }
    });
  }
  function onclickCard(e) {
    setClicked(true);
    if (e.currentTarget.getElementsByTagName("input")[0]) {
      e.currentTarget.getElementsByTagName("input")[0].checked = true;
      onChange(e.currentTarget.getElementsByTagName("input")[0], true);
      setChecked(
        e.currentTarget.getElementsByTagName("input")[0].value,
        e.currentTarget.getElementsByTagName("input")[0].name
      );
    }

    if (dataState.appointmentType) {
      if (!(curValue === dataState.appointmentType)) {
        clearCheckboxes(name);
      }
    } else {
      clearCheckboxes(name);
    }
  }

  useEffect(() => {
    if (curValue == index) {
      setClicked(true);
    }
  }, []);

  return (
    <StyledRadioButtonCard>
      <div
        className={`dds-radio-button-card-wrapper ${
          index == curValue ? "selected-box" : ""
        }`}
        onClick={onclickCard}
      >
        <div className="dds-radio-button-card-content">
          <label className="dds-radio-button-card-row">
            <input
              id={id + 1}
              type="radio"
              name={name}
              aria-label={ariaLabels}
              value={index}
              aria-describedby={"error-" + name}
              onClick={onclickCard}
              onChange={onChange}
              checked={index == curValue}
            />
            <span htmlFor={id}>{name}</span>
          </label>
          <div className="dds-radio-button-card-subtitle">{subtitle}</div>
          {topicsDetailsObj.length &&
            click &&
            index == curValue &&
            index != 3 && (
              <div className="dds-radio-button-card-text">
                {topicsDetailsObj.map((obj, i) => (
                  <Checkbox
                    name={obj.name}
                    subtitle={obj.subtitle}
                    checked={obj.isChecked}
                    handleChange={handleStandardTopics}
                    appointType={name}
                  />
                ))}
              </div>
            )}
        </div>
      </div>
    </StyledRadioButtonCard>
  );
};
export default RadioButtonCard;
