import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";

import styled from "styled-components";

import { mediaQueries } from "../common/mediaQueries";
import { DataContext } from "../common/context/DataContext";

const StyledCheckBox = styled.div`
  display: flex;
  .dds-checkbox-wrapper {
    display: flex;
    align-items: center;
  }
  input[type="checkbox"] {
    display: none;
  }
  .dds-checkbox-span {
    height: 18px;
    width: 18px;
    border: 2px solid black;
    background-color: white;
    margin-right: 10px;
    margin-top: -20px;
    @media ${mediaQueries.underS} {
      margin-top: -35px;
    }
  }
  .dds-checkbox-wrapper input:active ~ .dds-checkbox-span {
    background-color: white;
  }
  .dds-checkbox-wrapper input:checked ~ .dds-checkbox-span {
    background-color: white;
  }
  .dds-checkbox-span:after {
    content: "";
    display: none;
  }
  .dds-checkbox-wrapper input:checked ~ .dds-checkbox-span:after {
    display: block;
    margin-left: 5px;
    margin-top: 1px;
  }
  .dds-checkbox-wrapper .dds-checkbox-span:after {
    left: 8px;
    bottom: 5px;
    width: 4px;
    height: 10px;
    border: solid var(--secondary);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(49deg);
    -ms-transform: rotate(49deg);
    transform: rotate(49deg);
  }
  .dds-checkbox-text {
    margin: 6px;
    @media ${mediaQueries.underS} {
      max-width: 220px;
    }
  }
  .dds-checkbox-title {
    font-family: var(--primary);
    color: var(--fordBlue);
    font-size: 20px;
  }
  .dds-checkbox-subtitle {
    font-size: 14px;
    color: var(--fordSlate);
  }
`;

const Checkbox = ({ name, subtitle, checked, handleChange, appointType }) => {
  useEffect(() => {}, []);
  const handleCheckbox = (e) => {
    handleChange(e, appointType);
  };
  return (
    <StyledCheckBox>
      <label className="dds-checkbox-wrapper">
        <input
          id={`cb-${name}`}
          type="checkbox"
          checked={checked}
          onChange={handleCheckbox}
          name={name}
        ></input>
        <span className="dds-checkbox-span"></span>
        <div htmlFor={`cb-${name}`} className="dds-checkbox-text">
          <p className="dds-checkbox-title">{name}</p>
          <p className="dds-checkbox-subtitle">{subtitle}</p>
        </div>
      </label>
    </StyledCheckBox>
  );
};

Checkbox.propTypes = {
  items: PropTypes.array,
  handleChange: PropTypes.func,
  tabIndex: PropTypes.string,
};

Checkbox.defaultProps = {
  items: ["default item 1"],
  tabIndex: "0",
  required: false,
};

export default Checkbox;
