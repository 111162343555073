import React, { useContext } from "react";
import { mediaQueries } from "./common/mediaQueries";
import cancelIcon from "../assets/img/cancel-close.svg";
import importantIcon from "../assets/img/important-icon.svg";
import styled from "styled-components";
import { getExitUrl } from "./constants";
import { AppContext } from "../components/common/context/AppContext";

const StyledStickyError = styled.div`
  & {
    font-family: "FordMotion";
    margin: auto;
    position: fixed;
    top: 88px;
    left: 0;
    right: 15px;
    max-width: 600px;
    height: 70px;
    background-color: #f9e8ea;
    padding: 16px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
    @media ${mediaQueries.underM} {
      margin-top: 45px;
      margin-left: 15px;
      padding: 20px 20px;
      button {
        margin-bottom: 65px;
      }
    }
  }
`;

const ErrorTextContainer = styled.div`
display: flex;
align-itmes; center;
`;

const ErrorIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 12px;
`;

const ErrorText = styled.p`
  margin: 0;
`;

const LinkText = styled.button`
  color: black;
  background: none;
  border: none;
  text-decoration: underline;
  font-size: inherit;
  font-family: inherit;
  padding: 0;
  margin: 0;
  cursor: pointer;
`;

const CloseButton = styled.button`
  margin-bottom: 45px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`;

const StickyError = ({ onClose }) => {
  const { appState } = useContext(AppContext);

  function goToDashboard() {
    window.location.assign(getExitUrl(appState.orderId) + appState.orderId);
  }

  return (
    <StyledStickyError>
      <ErrorTextContainer>
        <ErrorIcon src={importantIcon} alt="Error" />
        <ErrorText>
          <strong>Sorry, we've encountered an error.</strong> <br />
          We can't process your request at this time. Please try again later
          <br /> or {""}
          <LinkText onClick={goToDashboard}>return to dashboard.</LinkText>
        </ErrorText>
      </ErrorTextContainer>
      <CloseButton onClick={onClose}>
        <img src={cancelIcon} alt="Close" />
      </CloseButton>
    </StyledStickyError>
  );
};
export default StickyError;
