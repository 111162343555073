import React, { useContext } from "react";

import StepHeader from "../components/common/StepHeader";
import Header from "../components/common/Header";
import DdsButton from "../components/common/DdsButton";
import { mediaQueries } from "./common/mediaQueries";
import { DataContext } from "../components/common/context/DataContext";

import styled from "styled-components";

const StyledErrorPage = styled.div`
  & {
    font-family: "FordMotion";
    margin: auto;
    .bold-text {
      font-weight: bold;
    }
    .error-container {
      padding: 64px 16px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      @media ${mediaQueries.underM} {
        padding: 20px 16px;
      }
    }

    .tradein-vehicle-tradein-subtext {
      font-size: 10px;
    }
    button {
      margin-top: 40px;
    }
  }
`;

const StyledStepHeader = styled.div`
  text-align: left;
  max-width: 656px;
  position: relative;
  z-index: 1;
  margin: 20px 0;
  .dds-header-note {
    margin: 20px;
    font-family: var(--fontPrimary);
    color: var(--primary);
    font-size: var(--fz-xs);
    letter-spacing: 1px;
  }
`;

export default function CompletedPage() {
  function goBack() {
    window.history.go(-1);
  }
  const { dataState } = useContext(DataContext);
  const { dealerPhone, dealerName } = dataState.vehInfo;

  console.log(dataState, "dataState");
  return (
    <StyledErrorPage>
      <div className="error-container">
        <StyledStepHeader>
          <h1 className="dds-main-header">Appointment completed.</h1>
          <h2 className="dds-subtext">
            Our records show you’ve picked up your vehicle. If this isn’t right,
            please contact <span className="bold-text">{dealerName}</span> at{" "}
            <span className="bold-text">{dealerPhone}</span>.
          </h2>
        </StyledStepHeader>

        <DdsButton type="primary" text="Return to Dashboard" action={goBack} />
      </div>
    </StyledErrorPage>
  );
}
