import { css } from "styled-components";

const variables = css`
  :root {
    --fordBlue: #00095b;
    --fordSlate: #8c8c8c;
    --fordGrey: #8c8c8c;
    --accentBlue: #0142bd;
    --borderLightGrey: #dfdfdf;
    --grabber: #1700f4;
    --grabberHover: #e8ecef;
    --primary: var(--fordSlate);
    --secondary: var(--fordBlue);
    --borderDarkGrey: #9c9c9c;
    --highlightblue: #0142bd;
    --checkboxgrey: #6e6e6e;
    --disclaimerBlue: #00142e;
    --linkBlue: #0068d0;
    --black: ##010203;

    --fz-xxxs: 8px;
    --fz-xxs: 11px;
    --fz-xs: 12px;
    --fz-xs2: 13px;
    --fz-sm: 14px;
    --fz-sm2: 15px;
    --fz-md: 16px;
    --fz-md2: 17px;
    --fz-lg: 18px;
    --fz-lg2: 20px;
    --fz-xl: 24px;
    --fx-mxl: 30px;
    --fx-mxl2: 32px;
    --fz-xxl: 34px;
    --fz-xxl2: 38px;
    --fz-xxxl: 40px;
    --fz-heading: 60px;

    --fontPrimary: "FordMotion";
    --fontSecondary: "FordMotion";
  }
`;
export default variables;
