import React, { useEffect, useContext } from "react";
import StepHeader from "../components/common/StepHeader";
import Header from "../components/common/Header";
import DdsButton from "../components/common/DdsButton";
import { mediaQueries } from "./common/mediaQueries";
import { pageLoadTags, onClick } from "./common/utils/analytics";
import { DataContext } from "./common/context/DataContext";
import { AppContext, AppACTIONS } from "./common/context/AppContext";

import styled from "styled-components";

const StyledErrorPage = styled.div`
  & {
    font-family: "FordMotion";
    margin: auto;
    .error-container {
      padding: 64px 16px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      @media ${mediaQueries.underM} {
        padding: 20px 16px;
      }
    }
    .tradein-vehicle-tradein-subtext {
      font-size: 10px;
    }
    button {
      margin-top: 40px;
    }
  }
`;

export default function ErrorPage() {
  const { dataState } = useContext(DataContext);
  const { appDispatch } = useContext(AppContext);
  const { model, year } = dataState.vehInfo;
  const nameplate = model
    ? model === "Mustang Mach-E"
      ? "ford mach-e"
      : "ford f-150 lightning"
    : " ";
  const category = model === "Mustang Mach-E" ? "suv" : "lightning";
  function goBack() {
    onClick.errorPage(nameplate, year, model, "back");
    window.history.go(-1);
  }
  useEffect(() => {
    appDispatch({
      type: AppACTIONS.currentStepUpdated,
      payload: 0,
    });
    pageLoadTags.errorpage("na", "na", "na", "na");
  }, []);
  return (
    <StyledErrorPage>
      <div className="error-container">
        <StepHeader
          mainHead={"Sorry, we've encountered an error."}
          subHead={
            "We can't continue with your request at this time. Please try again later."
          }
        />
        <DdsButton type="primary" text="Return to Dashboard" action={goBack} />
      </div>
    </StyledErrorPage>
  );
}
