import React, { useState, useContext } from "react";
import styled from "styled-components";
import { mediaQueries } from "./mediaQueries";
import { AppContext } from "../../components/common/context/AppContext";

import completed from "../../assets/img/completed.png";

const MainContainer = styled.div`
  width: 100%;
  max-width: 489px;
  @media ${mediaQueries.underS} {
    max-width: 288px;
  }
  margin: 0 auto;
  padding: 0 16px;
  margin-top: 30px;
`;

const StepContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  .active {
    background-color: #00095b;
    color: #ffffff;
  }
  .completed {
    background-image: url("../../assets/img/completed.png");
  }
  :before {
    content: "";
    position: absolute;
    background: #8c8c8c;
    height: 1px;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  :after {
    content: "";
    position: absolute;
    background: #8c8c8c;
    height: 4px;
    width: ${({ width }) => width};
    top: 50%;
    transition: 0.4s ease;
    transform: translateY(-50%);
    left: 0;
  }
`;

const StepWrapper = styled.div`
  position: relative;
  z-index: 1;
  img {
    @media ${mediaQueries.underS} {
      width: 32px;
      height: 32px;
    }
  }
`;

const StepStyle = styled.div`
  width: 40px;
  height: 40px;
  @media ${mediaQueries.underS} {
    width: 32px;
    height: 32px;
  }
  border-radius: 50%;
  background-color: #ffffff;
  border: 1px solid #8c8c8c;
  transition: 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StepCount = styled.span`
  font-size: 16px;
  font-family: var(--fontPrimary);
  font-weight: 700;
  color: #5e5e5e;
  .active {
    color: #ffffff;
  }
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const StepTracker = () => {
  const [activeStep, setActiveStep] = useState(1);
  const { appState } = useContext(AppContext);

  const nextStep = () => {
    setActiveStep(activeStep + 1);
  };

  const prevStep = () => {
    setActiveStep(activeStep - 1);
  };

  const totalSteps = 3;

  const width = `${(100 / (totalSteps - 1)) * (activeStep - 1)}%`;

  // check if there is any vehicle info in dataState
  const apptStatus =
    appState.appointmentStatus !== "ERROR" &&
    appState.appointmentStatus !== "COMPLETE";

  console.log(appState.appointmentStatus);

  return (
    <>
      {!apptStatus || appState.currentStep > 3 ? (
        ""
      ) : (
        <MainContainer>
          <StepContainer width={width}>
            {[1, 2, 3].map((x) => (
              <StepWrapper key={x}>
                {appState.completedSteps.find((step) => step === x) &&
                !(appState.currentStep === x) ? (
                  <img className="completed-image" src={completed} alt="" />
                ) : (
                  <StepStyle
                    className={`
          ${
            x === appState.currentStep
              ? "active"
              : appState.completedSteps.find((step) => step === x)
              ? "completed"
              : ""
          }`}
                  >
                    <StepCount
                      className={`
          ${
            x === appState.currentStep
              ? "active"
              : appState.completedSteps.find((step) => x === step)
              ? "completed"
              : ""
          }`}
                    >
                      {x}
                    </StepCount>
                  </StepStyle>
                )}
              </StepWrapper>
            ))}
          </StepContainer>
        </MainContainer>
      )}
    </>
  );
};
export default StepTracker;
