import React, { useContext, useEffect, useState } from "react";
import StepHeader from "./common/StepHeader";
import styled from "styled-components";
import { StepHeaders } from "../components/common/HeaderCopy";
import VehInfo from "./common/VehInfo";
import AppointmentDetails from "./common/AppointmentDetails";
import DdsButton from "./common/DdsButton";
import { useNavigate } from "react-router-dom";
import {
  AppContext,
  AppACTIONS,
} from "../components/common/context/AppContext";
import api from "../api";
import { DataContext } from "../components/common/context/DataContext";
import { APPOINTMENT_TYPE } from "./constants";
import { topicsDetailsObj } from "./copy";
import { mediaQueries } from "./common/mediaQueries";
import { convertTime } from "../helpers/util";
import {
  pageLoadTags,
  onClick,
} from "../components/common/utils/analytics/index";
import StickyError from "./StickyErrorHeader";

const StyledSummary = styled.div`
padding-top: ${props => props.$isStickyVisible ? '87px' : '0'};
  && {
    .dds-buttons-container {
      margin-top: 30px;
      flex-direction: column;
      @media ${mediaQueries.underM} {
        button {
          width: 100%;
          justify-content: center;
        }
      }
      button:nth-child(2) {
        margin-top: 15px;
        width: 180px;
        justify-content: center;
        @media ${mediaQueries.underM} {
          width: 100%;
        }
      }
    }
  }
`;

const Summary = () => {
  let navigate = useNavigate();
  const { appDispatch, appState } = useContext(AppContext);
  const { dataState, dataDispatch } = useContext(DataContext);

  const { appointmentBooked, notes, vehInfo } = dataState;

  let formattedTime = convertTime(dataState.appointmentBooked);

  const apptId = appState.appointmentInfo.id || vehInfo?.appointment?.id;
  const dateTimeString = new Date(appointmentBooked);

  const year = dateTimeString.getFullYear();
  const month = String(dateTimeString.getMonth() + 1).padStart(2, "0");
  const day = String(dateTimeString.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  const { model } = dataState.vehInfo;
  const nameplate =
    model === "Mustang Mach-E" ? "ford mach-e" : "ford f-150 lightning";
  const category = model === "Mustang Mach-E" ? "suv" : "lightning";


  const [isStickyVisible, setIsStickyVisible] = useState(false);
  const handleCloseSticky = () => {
    setIsStickyVisible(!isStickyVisible);
  };


  useEffect(() => {
    pageLoadTags.vehiclePickupReviewDetails(nameplate, category, model, year);
    appDispatch({
      type: AppACTIONS.currentStepUpdated,
      payload: 3,
    });
  }, [appDispatch]);

  function handleSubmit() {
    dataDispatch({
      type: "SHOW_LOADING_SPINNER",
      payload: true,
    });
    appDispatch({
      type: AppACTIONS.completedStepsUpdated,
      payload: 3,
    });
    const orientationTopics = [];
    topicsDetailsObj.forEach((element) => {
      if (element.isChecked) {
        orientationTopics.push(element.name);
      }
    });
    const apptObj = {
      additionalDetails: notes,
      date: formattedDate,
      time: formattedTime,
      dealerInteraction: APPOINTMENT_TYPE[dataState.appointmentType - 1]?.value,
      // dealershipEmployee: dataState.vehInfo.dealerName,
      orderId: appState.orderId,
      orientationTopics,
      // token: appState.accessToken,
    };
    console.log(apptId, "apptId");
    onClick.vehiclePickupReviewDetails(nameplate, year, model, "request appt");
    if (!apptId) {
      api
        .createAppointment(apptObj, appState.accessToken)
        .then((data) => {
          appDispatch({
            type: AppACTIONS.appointmentInfoUpdated,
            payload: { ...data },
          });
          dataDispatch({
            type: "SHOW_LOADING_SPINNER",
            payload: false,
          });
          navigate("/appointmentRequest");
        })
        .catch((error) => {
          dataDispatch({
            type: "SHOW_LOADING_SPINNER",
            payload: false,
          });
          setIsStickyVisible(true);
          console.log("Error:" + error);
        });
    } else {
      api
        .updateAppointment(apptObj, apptId, appState.accessToken)
        .then((data) => {
          appDispatch({
            type: AppACTIONS.appointmentInfoUpdated,
            payload: { ...data },
          });
          dataDispatch({
            type: "SHOW_LOADING_SPINNER",
            payload: false,
          });
          navigate("/appointmentRequest");
        })
        .catch((error) => {
          dataDispatch({
            type: "SHOW_LOADING_SPINNER",
            payload: false,
          });
          setIsStickyVisible(true);
          console.log("Error:" + error);
        });
    }
  }
  function handleBack() {
    onClick.vehiclePickupReviewDetails(nameplate, year, model, "back");
    navigate("/appointments");
  }

  return (
    <>{isStickyVisible && <StickyError onClose={handleCloseSticky}/>}
<StyledSummary>
      <StepHeader
        mainHead={StepHeaders.summary.main}
        subHead={StepHeaders.summary.sub} />
      <VehInfo data={vehInfo} />
      <AppointmentDetails editlink />
      <div className="dds-buttons-container">
        <DdsButton
          type="primary"
          text="Request appointment"
          action={handleSubmit} />
        <DdsButton type="secondary" text="Back" action={handleBack} />
      </div>
    </StyledSummary>
    </>
  );
};
export default Summary;
