import React, { useContext } from "react";
import styled from "styled-components";
import { AppContext } from "../common/context/AppContext";

import { mediaQueries } from "../common/mediaQueries";

const StyledStepHeader = styled.div`
  text-align: left;
  max-width: 656px;
  position: relative;
  z-index: 1;
  margin: 20px 0;
  .dds-header-note {
    margin: 20px;
    font-family: var(--fontPrimary);
    color: var(--primary);
    font-size: var(--fz-xs);
    letter-spacing: 1px;
  }
`;

const StepHeader = (props) => {
  // const { brand, market } = useContext(AppContext) || "";
  // const stepHeaderClass = props.className
  //   ? ` ${props.className} ${brand} ${market}`
  //   : `${brand} ${market}`;

  return (
    <StyledStepHeader>
      <h1 className="dds-main-header">{props.mainHead}</h1>
      {props.subHead && (
        <h2
          className="dds-subtext"
          style={{ maxWidth: props.maxWidth, marginBottom: props.marginBottom }}
        >
          {props.subHead}
        </h2>
      )}
      {props.note && (
        <h3 className="dds-header-note" style={{ maxWidth: props.maxWidth }}>
          {props.note}
        </h3>
      )}
    </StyledStepHeader>
  );
};

export default StepHeader;
