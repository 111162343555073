import { createGlobalStyle } from "styled-components";
import { mediaQueries } from "./mediaQueries";
import variables from "./variables";

const GlobalStyle = createGlobalStyle`
&& {
    ${variables}
    body{
        font-family: var(--fontPrimary);
    }
    p {
        margin: 0px;
      }
    h1,h2 {
        margin:0px;
    }
    main{
        max-width: 656px;
        margin: auto;
        @media ${mediaQueries.underM} {
            padding: 0 16px;
          }
    }
    .dds-app-wrapper-container{
        width:100%;
        line-height:24px;
        font-family: var(--fontPrimary);    
        .dds-buttons-container {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin: 20px auto 20px;
            align-items: center;
            @media ${mediaQueries.underM} {
                margin-top: 20px;
            }
            .link-button-container {
                margin: 15px 0 0 8px;
            }
        }     
    }
    .dds-main-header {
        font-size: var(--fz-xxl2);
        font-family: var(--fontSecondary);
        font-weight: 400;
        line-height: 1.29;
        @media ${mediaQueries.underM} {
            font-size: var(--fz-xxl2);
        }
        color:var(--secondary);
        margin-bottom:10px;
    }

    .dds-subheader {
        color: var(--secondary);
        letter-spacing: 1px;
        font-size: var( --fz-md);
        font-family: var(--fontSecondary);
    }

    .dds-subtext {
        font-family: var(--fontPrimary);
        font-size: var( --fz-md);
        max-width: 656px;       
        margin: 0 auto;
        text-transform: none;
        color:var(--secondary);
        font-weight:400;
    }
    .dds-edit-buttons-container {
        display: flex;
        justify-content: center;
        padding: 40px;
        button {
            width: 140px;
            justify-content: center !important;
        }
    }
    .dds-loadingWrapper {
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 999;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    }

}`;
export default GlobalStyle;
