function resetOnClick() {
  try {
    window.digitaldata.onclick = {
      onclickLinkName: "",
      onclick: "",
    };
  } catch (err) {
    console.log("Analytics blocked", err);
  }
}

export default {
  vehiclePickUpStart: (
    nameplate,
    modelYear,
    model,
    action,
    category,
    subcategory
  ) => {
    try {
      resetOnClick();
      const catSub = category ? `${category}${subcategory}:` : "";
      window.digitaldata.onclick.onclickLinkName = `fv:manage:dds:vehicle pickup:start:cta:${catSub}${action}:${nameplate}`;
      window.digitaldata.onclick.onclick = `manage:dds:vehicle pickup:start:cta click:${catSub}${action}`;
      window.digitaldata.page.userLanguage = "eng";
      window.digitaldata.page.client = "ford-us";
      window.digitaldata.page.site = "fordvehicles.com";
      window.digitaldata.vehicle.modelYear = `${modelYear}`;
      window.digitaldata.vehicle.nameplate = `${nameplate}`;
      window.digitaldata.vehicle.bodyModelTrim = `${model}`;
      window.digitaldata.page.radUIVersion = "ui:rad:pc";
      window._satellite.track("ecommInternalCtaClick");
    } catch (err) {
      console.log("Analytics blocked", err);
    }
  },
  vehiclePickupDateTime: (nameplate, modelYear, model, action) => {
    try {
      resetOnClick();
      window.digitaldata.onclick.onclickLinkName = `fv:manage:dds:vehicle pickup:date and time:cta:${action}:${nameplate}`;
      window.digitaldata.onclick.onclick = `manage:dds:vehicle pickup:date and time:cta click:${action}`;
      window.digitaldata.page.userLanguage = "eng";
      window.digitaldata.page.client = "ford-us";
      window.digitaldata.page.site = "fordvehicles.com";
      window.digitaldata.vehicle.modelYear = `${modelYear}`;
      window.digitaldata.vehicle.nameplate = `${nameplate}`;
      window.digitaldata.vehicle.bodyModelTrim = `${model}`;
      window.digitaldata.page.radUIVersion = "ui:rad:pc";
      window._satellite.track("ecommInternalCtaClick");
    } catch (err) {
      console.log("Analytics blocked", err);
    }
  },
  vehiclePickupReviewDetails: (nameplate, modelYear, model, action) => {
    try {
      resetOnClick();
      // window.digitaldata.onclick.onclickLinkName = `fv:manage:cvdt:vehicle pickup:review details:cta:{edit date or time|edit appt details|request appt|back|close tool}:<nameplate>`;
      window.digitaldata.onclick.onclickLinkName = `fv:manage:dds:vehicle pickup:review details:cta:${action}:${nameplate}`;
      window.digitaldata.onclick.onclick = `manage:dds:vehicle pickup:review details:cta click:${action}`;
      window.digitaldata.page.userLanguage = "eng";
      window.digitaldata.page.client = "ford-us";
      window.digitaldata.page.site = "fordvehicles.com";
      window.digitaldata.vehicle.modelYear = `${modelYear}`;
      window.digitaldata.vehicle.nameplate = `${nameplate}`;
      window.digitaldata.vehicle.bodyModelTrim = `${model}`;
      window.digitaldata.page.radUIVersion = "ui:rad:pc";
      window._satellite.track("ecommInternalCtaClick");
    } catch (err) {
      console.log("Analytics blocked", err);
    }
  },
  vehiclePickupRequestAppointment: (nameplate, modelYear, model, action) => {
    try {
      resetOnClick();
      window.digitaldata.onclick.onclickLinkName = `fv:manage:dds:vehicle pickup:request appt confirmed:cta:${action}:${nameplate}`;
      window.digitaldata.onclick.onclick = `manage:dds:vehicle pickup:request appt confirmed:cta click:${action}`;
      window.digitaldata.page.userLanguage = "eng";
      window.digitaldata.page.client = "ford-us";
      window.digitaldata.page.site = "fordvehicles.com";
      window.digitaldata.vehicle.modelYear = `${modelYear}`;
      window.digitaldata.vehicle.nameplate = `${nameplate}`;
      window.digitaldata.vehicle.bodyModelTrim = `${model}`;
      window.digitaldata.page.radUIVersion = "ui:rad:pc";
      window._satellite.track("ecommInternalCtaClick");
    } catch (err) {
      console.log("Analytics blocked", err);
    }
  },
  vehiclePickupAppointmentConfirmed: (nameplate, modelYear, model, action) => {
    try {
      resetOnClick();
      window.digitaldata.onclick.onclickLinkName = `fv:manage:dds:vehicle pickup:appt confirmed:cta:${action}:${nameplate}`;
      window.digitaldata.onclick.onclick = `manage:dds:vehicle pickup:appt confirmed:cta click:${action}`;
      window.digitaldata.page.userLanguage = "eng";
      window.digitaldata.page.client = "ford-us";
      window.digitaldata.page.site = "fordvehicles.com";
      window.digitaldata.vehicle.modelYear = `${modelYear}`;
      window.digitaldata.vehicle.nameplate = `${nameplate}`;
      window.digitaldata.vehicle.bodyModelTrim = `${model}`;
      window.digitaldata.page.radUIVersion = "ui:rad:pc";
      window._satellite.track("ecommInternalCtaClick");
    } catch (err) {
      console.log("Analytics blocked", err);
    }
  },
  vehiclePickupAppointmentCancel: (nameplate, modelYear, model, action) => {
    resetOnClick();
    window.digitaldata.onclick.onclickLinkName = `fv:manage:dds:vehicle pickup:cancel:start:cta:${action}:${nameplate}`;
    window.digitaldata.onclick.onclick = `manage:dds:vehicle pickup:cancel:start:cta click:${action}`;
    window.digitaldata.page.userLanguage = "eng";
    window.digitaldata.page.client = "ford-us";
    window.digitaldata.page.site = "fordvehicles.com";
    window.digitaldata.vehicle.modelYear = `${modelYear}`;
    window.digitaldata.vehicle.nameplate = `${nameplate}`;
    window.digitaldata.vehicle.bodyModelTrim = `${model}`;
    window.digitaldata.page.radUIVersion = "ui:rad:pc";
    window._satellite.track("ecommInternalCtaClick");
  },
  vehiclePickupAppointmentCancelComplete: (
    nameplate,
    modelYear,
    model,
    action
  ) => {
    resetOnClick();
    window.digitaldata.onclick.onclickLinkName = `fv:manage:dds:vehicle pickup:cancel:complete:cta:${action}:${nameplate}`;
    window.digitaldata.onclick.onclick = `manage:dds:vehicle pickup:cancel:complete:cta click:${action}`;
    window.digitaldata.page.userLanguage = "eng";
    window.digitaldata.page.client = "ford-us";
    window.digitaldata.page.site = "fordvehicles.com";
    window.digitaldata.vehicle.modelYear = `${modelYear}`;
    window.digitaldata.vehicle.nameplate = `${nameplate}`;
    window.digitaldata.vehicle.bodyModelTrim = `${model}`;
    window.digitaldata.page.radUIVersion = "ui:rad:pc";
    window._satellite.track("ecommInternalCtaClick");
  },
  errorPage: (nameplate, modelYear, model, action) => {
    resetOnClick();
    window.digitaldata.onclick.onclickLinkName = `fv:manage:dds:vehicle pickup:error:cta:${action}:${nameplate}`;
    window.digitaldata.onclick.onclick = `manage:dds:vehicle pickup:error:cta click:${action}`;
    window.digitaldata.page.userLanguage = "eng";
    window.digitaldata.page.client = "ford-us";
    window.digitaldata.page.site = "fordvehicles.com";
    window.digitaldata.vehicle.modelYear = `${modelYear}`;
    window.digitaldata.vehicle.nameplate = `${nameplate}`;
    window.digitaldata.vehicle.bodyModelTrim = `${model}`;
    window.digitaldata.page.radUIVersion = "ui:rad:pc";
    window._satellite.track("ecommInternalCtaClick");
  },
};
