import React from "react";
import PropTypes from "prop-types";
import Icon from "../common/icons/Icon";
import styled from "styled-components";

const BasicButton = styled.button`
  && {
    padding: 8px 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #0068d0;
    border-radius: 20px;
    border: none;
    color: #ffffff;
    font-family: var(--fontPrimary);
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
  }
`;
const StyledPrimaryBtn = styled(BasicButton)`
  && {
    svg {
      color: white;
      path {
        fill: currentColor;
      }
      font-size: 1.6rem;
      margin-left: 6px;
    }
    &.disabled {
      background-color: rgba(0, 0, 0, 0.4);
      span {
        color: rgba(255, 255, 255, 0.6);
      }
      svg {
        g {
          fill: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }
`;

const StyledSecBtn = styled(BasicButton)`
  && {
    background: white;
    color: var(--accentBlue);
    border: 1px solid var(--accentBlue);

    svg {
      color: var(--accentBlue);
      g,
      path {
        fill: currentColor;
      }
      font-size: 1.6rem;
      margin-right: 6px;
    }
  }
`;

const DdsButton = ({
  type,
  text,
  action,
  ariaLabel,
  className,
  id,
  iconType,
  disabled,
}) => {
  switch (type) {
    case "primary":
      return (
        <StyledPrimaryBtn
          aria-label={ariaLabel}
          onClick={action}
          primary
          id={id}
          className={disabled ? "disabled" : ""}
        >
          <span className="button-text"> {text} </span>
          {iconType && <Icon icon={iconType} />}
        </StyledPrimaryBtn>
      );
    // default primary button
    case "secondary":
      return (
        <StyledSecBtn
          aria-label={ariaLabel}
          onClick={action}
          primary
          id={id}
          className={disabled ? "disabled" : ""}
        >
          {iconType && <Icon icon={iconType} />}

          <span className="button-text"> {text} </span>
        </StyledSecBtn>
      );
    default:
      return (
        <StyledPrimaryBtn
          className="disabled"
          aria-label={ariaLabel}
          onClick={action}
          primary
          disabled
        >
          <span className="button-text"> {text} </span>
        </StyledPrimaryBtn>
      );
  }
};

DdsButton.propTypes = {
  ariaLabel: PropTypes.string,
  action: PropTypes.func,
  text: PropTypes.string,
  type: PropTypes.string.isRequired,
};

DdsButton.defaultProps = {
  text: "Submit",
  type: "submit",
};

export default DdsButton;
