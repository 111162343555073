import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import DdsButton from "./common/DdsButton";
import { useNavigate } from "react-router-dom";
import { StepHeaders } from "../components/common/HeaderCopy";
import StepHeader from "./common/StepHeader";
import { getExitUrl } from "./constants";
import { topicsDetailsObj } from "./copy";
import StickyError from "./StickyErrorHeader";

import {
  AppACTIONS,
  AppContext,
} from "../components/common/context/AppContext";
import {
  DataACTIONS,
  DataContext,
} from "../components/common/context/DataContext";
import api from "../api";
import { mediaQueries } from "./common/mediaQueries";
import {
  pageLoadTags,
  onClick,
} from "../components/common/utils/analytics/index";

const CancelAppointmentStyles = styled.div`
  padding-top: ${(props) => (props.$isStickyVisible ? "87px" : "0")};
  .dds-buttons-container {
    margin-top: 30px;
    flex-direction: column;
    @media ${mediaQueries.underM} {
      button {
        width: 100%;
        justify-content: center;
      }
    }
    button:nth-child(2) {
      margin-top: 15px;
      width: 180px;
      justify-content: center;
      @media ${mediaQueries.underM} {
        width: 100%;
      }
    }
  }
`;
const CancelAppointment = () => {
  let navigate = useNavigate();
  const [confirmation, setConfirmation] = useState(false);
  const { appState, appDispatch } = useContext(AppContext);
  const { dataState, dataDispatch } = useContext(DataContext);
  const { model, year } = dataState.vehInfo;
  const nameplate =
    model === "Mustang Mach-E" ? "ford mach-e" : "ford f-150 lightning";
  const category = model === "Mustang Mach-E" ? "suv" : "lightning";
  const [isStickyVisible, setIsStickyVisible] = useState(false);

  const handleCloseSticky = () => {
    setIsStickyVisible(!isStickyVisible);
  };

  useEffect(() => {
    pageLoadTags.vehiclePickupAppointmentCancel(
      nameplate,
      category,
      model,
      year
    );

    if (confirmation) {
      pageLoadTags.vehiclePickupAppointmentCancelComplete(
        nameplate,
        category,
        model,
        year
      );
      appDispatch({
        type: AppACTIONS.currentStepUpdated,
        payload: 6,
      });
    } else {
      appDispatch({
        type: AppACTIONS.currentStepUpdated,
        payload: 7,
      });
    }
  }, [appDispatch]);

  function cancel() {
    const appointmentId =
      appState.appointmentInfo.id || dataState.vehInfo.appointment.id;
    api
      .deleteAppointment(appointmentId, appState.accessToken)
      .then((data) => {
        setConfirmation(true);

        appDispatch({
          type: AppACTIONS.resetData,
        });
        dataDispatch({
          type: DataACTIONS.resetData,
        });

        topicsDetailsObj.forEach((val) => {
          val.isChecked = false;
        });
        onClick.vehiclePickupAppointmentCancel(
          nameplate,
          year,
          model,
          "cancel appt"
        );
        pageLoadTags.vehiclePickupAppointmentCancelComplete(
          nameplate,
          category,
          model,
          year
        );
      })
      .catch((error) => {
        appDispatch({
          type: AppACTIONS.appointmentStatusUpdated,
          payload: "ERROR",
        });
        setIsStickyVisible(true);
        console.log("Error:" + error);
      });
  }
  function goToDashboard() {
    window.location.assign(getExitUrl(appState.orderId) + appState.orderId);
  }

  function handleAction(action) {
    if (action === "dashboard") {
      onClick.vehiclePickupAppointmentCancelComplete(
        nameplate,
        year,
        model,
        "back to dashboard"
      );
      goToDashboard();
    } else if (action === "new") {
      onClick.vehiclePickupAppointmentCancelComplete(
        nameplate,
        year,
        model,
        "new appt"
      );
      navigate("/");
    } else if (action === "neverMind") {
      onClick.vehiclePickupAppointmentCancel(
        nameplate,
        year,
        model,
        "nevermind"
      );
      navigate("/appointmentRequest");
    }
  }
  return (
    <>
      {" "}
      {isStickyVisible && <StickyError onClose={handleCloseSticky} />}
      {confirmation === true ? (
        <CancelAppointmentStyles>
          <StepHeader
            mainHead={StepHeaders.cancelConfirmation.main}
            subHead={StepHeaders.cancelConfirmation.sub}
          />
          <div className="dds-buttons-container">
            <DdsButton
              type="primary"
              text="Create a new appointment"
              action={() => handleAction("new")}
            />
            <DdsButton
              type="secondary"
              text="Return to dashboard"
              action={() => handleAction("dashboard")}
            />
          </div>
        </CancelAppointmentStyles>
      ) : (
        <CancelAppointmentStyles>
          <StepHeader
            mainHead={StepHeaders.cancel.main}
            subHead={StepHeaders.cancel.sub}
          />
          <div className="dds-buttons-container">
            <DdsButton
              type="primary"
              text="Cancel appointment"
              action={cancel}
            />
            <DdsButton
              type="secondary"
              text="Never Mind"
              action={() => handleAction("neverMind")}
            />
          </div>
        </CancelAppointmentStyles>
      )}
    </>
  );
};
export default CancelAppointment;
