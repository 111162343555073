import React, { useContext } from "react";
import styled from "styled-components";
import { mediaQueries } from "./mediaQueries";
import DdsLink from "./DdsLink";
import { DataContext } from "./context/DataContext";
import { AppContext } from "./context/AppContext";
import onClick from "./utils/analytics/onClick";

const StyledHeader = styled.header`
  && {
    display: flex;
    justify-content: space-between;
    padding: 25px 40px;
    align-items: center;
    height: 64px;
    background-color: #f0f0f0;
    padding: 0 56px;
    color: #00095b;
    @media ${mediaQueries.underS} {
      height: 65px;
      padding: 25px 27px;
    }
    font-weight: bold;
    .dds-icon-span {
      margin-left: 2px;
    }
    .dds-cancel-text {
      @media ${mediaQueries.underS} {
        margin-top: 5px;
      }
      font-size: 14px;
      font-family: var(--fontPrimary);
      font-weight: 700;
      a {
        text-decoration: none;
        color: var(--black);
      }
      svg {
        margin-bottom: 5px;
      }
    }
    .dds-header-text {
      font-size: 24px;
      font-family: var(--fontPrimary);
      font-weight: 700;
      @media ${mediaQueries.underS} {
        font-size: 20px;
      }
    }
  }
`;

const StyledCancelLink = styled(DdsLink)`
  && {
    margin-right: 60px;
    letter-spacing: 1px;
    @media ${mediaQueries.underM} {
      margin-right: 10px;
    }
    @media ${mediaQueries.underS} {
      font-size: var(--fz-xxs);
      letter-spacing: 0.5px;
      margin-right: 2px;
    }
    .secondary-link-text {
    }
  }
`;

const Header = () => {
  const { dataState } = useContext(DataContext);
  const { appState } = useContext(AppContext);
  const { model, year } = dataState.vehInfo;
  const nameplate =
    model === "Mustang Mach-E" ? "ford mach-e" : "ford f-150 lightning";
  const step = appState.currentStep;
  function goBack() {
    if (step === 0) {
      onClick.errorPage(nameplate, year, model, "close tool");
    } else if (step === 1)
      onClick.vehiclePickUpStart(nameplate, year, model, "close tool", "", "");
    else if (step === 2)
      onClick.vehiclePickupDateTime(nameplate, year, model, "close tool");
    else if (step === 3)
      onClick.vehiclePickupReviewDetails(nameplate, year, model, "close tool");
    else if (step === 4) {
      onClick.vehiclePickupRequestAppointment(
        nameplate,
        year,
        model,
        "close tool"
      );
    } else if (step === 5) {
      onClick.vehiclePickupAppointmentConfirmed(
        nameplate,
        year,
        model,
        "close tool"
      );
    } else if (step === 6) {
      onClick.vehiclePickupAppointmentCancel(
        nameplate,
        year,
        model,
        "close tool"
      );
    } else if (step == 7) {
      onClick.vehiclePickupAppointmentCancelComplete(
        nameplate,
        year,
        model,
        "close tool"
      );
    }
    setTimeout(function () {
      window.history.go(-1);
    }, 500);
  }

  return (
    <StyledHeader>
      <div className="dds-header-text">Schedule pickup</div>
      <div className="dds-cancel-text">
        <StyledCancelLink
          type="secondary"
          text="Cancel"
          ariaLabel="dds-cancel"
          action={goBack}
          icon="close"
        />
      </div>
    </StyledHeader>
  );
};

export default Header;
