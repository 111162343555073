export const StepHeaders = {
  home: {
    main: "What do you want your dealer to go over during your pickup?",
    sub: "Let us know which features you’d like to learn about so we can plan enough time for your appointment.",
  },
  appointment: {
    main: "Request a time",
    sub: "Please request a date and time below which your dealer will confirm within 24 hours.",
  },
  summary: {
    main: "Review appointment request details",
    sub: 'Please review your appointment request details. Select "edit" to make changes or "request appointment" to submit your appointment request.',
  },
  appointmentRequest: {
    main: "Appointment requested",
    sub: (
      <>
        You’ve requested to pick up your vehicle on{" "}
        <strong>December 16th, 2023 at 1:00 PM EST.</strong> A request
        confirmation email will be sent to{" "}
        <strong>amelia.smith@gmail.com.</strong> Your dealer will confirm the
        appointment within 24 hrs and you will receive a confirmation email.
      </>
    ),
  },
  appointmentConfirmation: {
    main: "Appointment confirmed",
    sub: (
      <>
        You are scheduled to pick up your vehicle on{" "}
        <strong>December 16th, 2023 at 1:00 PM EST.</strong> A confirmation
        email was sent to <strong>amelia.smith@gmail.com.</strong>
      </>
    ),
  },
  cancel: {
    main: "Cancel appointment",
    sub: "Are you sure you want to cancel your appointment? You will have to create a new appointment to pickup your vehicle.",
  },
  cancelConfirmation: {
    main: "Appointment cancelled",
    sub: "Your appointment as been cancelled. To create a new appointment, select “Create a new appointment.”",
  },
};
