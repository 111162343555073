import React, { useEffect, useContext } from "react";
import StepHeader from "./common/StepHeader";
import styled from "styled-components";
import { StepHeaders } from "../components/common/HeaderCopy";
import VehInfo from "./common/VehInfo";
import AppointmentDetails from "./common/AppointmentDetails";
import DdsButton from "./common/DdsButton";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "./common/ScrollToTop";

import {
  AppContext,
  AppACTIONS,
} from "../components/common/context/AppContext";
import {
  DataContext,
  DataACTIONS,
} from "../components/common/context/DataContext";
import DdsLink from "./common/DdsLink";
import { getExitUrl } from "./constants";
import { bookAppointmentDate } from "../helpers/util";
import { mediaQueries } from "./common/mediaQueries";
import {
  pageLoadTags,
  onClick,
} from "../components/common/utils/analytics/index";

const StyledAppointmentRequest = styled.div`
  && {
    .dds-buttons-container {
      margin-top: 30px;
      flex-direction: column;
      @media ${mediaQueries.underM} {
        button {
          width: 100%;
          justify-content: center;
        }
      }
      button:nth-child(2) {
        margin-top: 15px;
      }
      a {
        margin-top: 15px;
        text-decoration: none;
      }
    }
    b:nth-of-type(2) {
      text-transform: lowercase;
    }
  }
`;

const AppointmentRequest = () => {
  let navigate = useNavigate();
  const { appDispatch, appState } = useContext(AppContext);
  const { dataDispatch, dataState } = useContext(DataContext);
  function goToDashboard() {
    window.location.assign(getExitUrl(appState.orderId) + appState.orderId);
  }
  const dateTime = bookAppointmentDate(dataState.appointmentBooked);
  const email = dataState.vehInfo?.customerEmail;
  const { model, year } = dataState.vehInfo;
  const nameplate =
    model === "Mustang Mach-E" ? "ford mach-e" : "ford f-150 lightning";
  const category = model === "Mustang Mach-E" ? "suv" : "lightning";
  const subheader = (
    <>
      You’ve requested to pick up your vehicle on <b>{`${dateTime}`}.</b> A
      request confirmation email will be sent to{" "}
      <b className="no-mouseflow">{`${email}`}.</b> You will receive a
      confirmation email for the pickup appointment when your dealer confirms
      the appointment.
    </>
  );
  useEffect(() => {
    pageLoadTags.vehiclePickupRequestAppointment(
      nameplate,
      category,
      model,
      year
    );
    appDispatch({
      type: AppACTIONS.currentStepUpdated,
      payload: 4,
    });
    dataDispatch({
      type: DataACTIONS.edittedUpdated,
      payload: false,
    });
  }, [appDispatch]);

  function handleAction(action) {
    if (action === "dashboard") {
      onClick.vehiclePickupRequestAppointment(
        nameplate,
        year,
        model,
        "back to dashboard"
      );
      goToDashboard();
    } else if (action === "edit") {
      onClick.vehiclePickupRequestAppointment(
        nameplate,
        year,
        model,
        "edit appt"
      );
      navigate("/");
    } else {
      onClick.vehiclePickupRequestAppointment(
        nameplate,
        year,
        model,
        "cancel appt"
      );
      navigate("/cancel");
    }
  }
  return (
    <StyledAppointmentRequest>
      <ScrollToTop />

      <StepHeader
        mainHead={StepHeaders.appointmentRequest.main}
        subHead={subheader}
      />
      <VehInfo data={dataState.vehInfo} />
      <AppointmentDetails />
      <div className="dds-buttons-container">
        <DdsButton
          type="primary"
          text="Return to dashboard"
          action={() => handleAction("dashboard")}
        />
        <DdsButton
          type="secondary"
          text="Edit appointment"
          ariaLabel="dds-reschedule-appointment"
          action={() => handleAction("edit")}
        />
        <DdsLink
          type="primary"
          text="Cancel appointment"
          ariaLabel="dds-cancel-appointment"
          action={() => handleAction("cancel")}
        />
      </div>
    </StyledAppointmentRequest>
  );
};
export default AppointmentRequest;
