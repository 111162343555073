import React from "react";
import styled from "styled-components";
import { mediaQueries } from "../common/mediaQueries";

export const StyledRadioButtonCard = styled.div`
  .dds-radio-button-card-wrapper {
    border-radius: 8px;
    border: solid 1px var(--borderDarkGrey);
    background-color: white;
    font-family: var(--fontPrimary);
    .dds-radio-button-card-content {
      padding: 16px;
      .dds-radio-button-card-row {
        input[type="radio"] {
          -webkit-appearance: none;
          width: 16px;
          height: 15px;
          border: 2px solid var(--checkboxgrey);
          border-radius: 50%;
          outline: none;
          margin-right: 20px;
          @media ${mediaQueries.underS} {
            margin-right: 15px;
          }
        }
        input[type="radio"]:before {
          content: "";
          display: block;
          width: 60%;
          height: 60%;
          margin: 20% auto;
          border-radius: 50%;
        }
        input[type="radio"]:checked {
          border: 2px solid var(--fordBlue);
        }
        input[type="radio"]:checked:before {
          background: var(--fordBlue);
        }
        span {
          font-size: var(--fz-lg2);
          font-family: var(--primary);
          color: var(--fordBlue);
        }
      }
      position: relative;
      .dds-radio-button-card-icon {
        position: absolute;
        right: 0;
        top: 25%;
        padding-right: 20px;
      }
      .dds-radio-button-card-subtitle {
        @media ${mediaQueries.underS} {
          max-width: 326px;
          line-height: 20px;
          margin-left: 38px;
        }
        font-size: var(--fz-sm);
        color: var(--fordSlate);
        padding-bottom: 5px;
        margin-left: 45px;
      }
    }
  }
  .selected-box {
    border: 2px solid var(--highlightblue);
  }
  .dds-radio-button-card-text {
    margin-left: 38px;
  }
  margin-bottom: 20px;
`;
