import React from "react";

import styled from "styled-components";
import { StyledRadioButtonCard } from "../styles/StyledRadioButtonCard";
import { mediaQueries } from "./mediaQueries";

const StyledTimePicker = styled.div`
  margin: auto;
  margin-top: 16px;
  .dds-radio-button-card-wrapper {
    width: 45%;
    margin-bottom: 16px;
    margin-right: 16px;
    display: inline-block;
    @media ${mediaQueries.underS} {
      width: 99%;
      margin-right: 0;
    }
  }
`;
const TimeRadioButtons = ({ times, selectedTime, onTimeChange, curValue }) => {
  function onclickCard(e) {
    if (e.currentTarget.getElementsByTagName("input")[0]) {
      e.currentTarget.getElementsByTagName("input")[0].checked = true;
      onTimeChange(e.currentTarget.getElementsByTagName("input")[0].value);
    }
  }

  return (
    <StyledTimePicker>
      <StyledRadioButtonCard>
        {times.map((time, index) => (
          <div
            className={`dds-radio-button-card-wrapper ${
              selectedTime == time.value ? "selected-box" : ""
            }`}
            key={time + index}
            onClick={onclickCard}
          >
            <div className="dds-radio-button-card-content" key={index}>
              <label className="dds-radio-button-card-row">
                <input
                  type="radio"
                  id={time.value}
                  // name="time"
                  value={time.value}
                  checked={selectedTime === time.value}
                  onChange={() => onTimeChange(time.value)}
                />
                <span htmlFor={time.value}>{time.label}</span>
              </label>
            </div>
          </div>
        ))}
      </StyledRadioButtonCard>
    </StyledTimePicker>
  );
};

export default TimeRadioButtons;
