const fmaKeys = {
  qa: {
    clientId: "c9c884a3-5888-4f37-b581-20008a534f05",
    appId: "c47bd602-7233-4ed2-b078-ba8def9aa154",
  },
  prod: {
    appId: "4e201c62-d674-432a-89b6-d65806bdc1e2",
    clientId: "5a5b1b17-8adb-4803-82b3-4a9d264dd89f",
  },
};

const currentHostname = window.location.hostname;

// pref = dev
const qaEnv = "https://wwwqa.account.ford.com/etc/fd/fma/bundle.js";
const prodEnv = "https://www.account.ford.com/etc/fd/fma/bundle.js";

// check if not dev/qa, use prod

const prodCondition =
  !currentHostname.includes("dev") && !currentHostname.includes("qa");

export const getFmaKeys = prodCondition ? fmaKeys.prod : fmaKeys.qa;
export const getFmaUrl = prodCondition ? prodEnv : qaEnv;
